import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Eject as EjectIcon } from '@material-ui/icons';
import Select from 'react-select';
import DebounceButton from './DebounceButton';

class Slot extends Component {
    render() {
        const { params, num, sno, extract, updateAdminStatus, updateConflictStatus } = this.props;
        if (params)
            return (
                <div
                    className="powerbank bank"
                    id={'powerbank_' + num}
                >
                    <div className="params">
                        <div className="params-button">
                            <div
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    top: '-5px',
                                    color: '#FFF',
                                }}
                            >
                                #{num}
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    flex: 1,
                                    fontSize: '1rem',
                                    marginRight: '8px',
                                }}
                            >
                                {/* <div style={{ width: '100#' }}> */}
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        flexWrap: 'wrap',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <div>Слот: {num}</div>
                                    <div className="dno">{params.dno}</div>
                                    <div className="">
                                        <label className="bc">{params.bc}%</label>
                                    </div>
                                </div>
                            </div>
                            <DebounceButton
                                icon={<EjectIcon />}
                                onRefresh={() =>
                                    extract({
                                        sno,
                                        position: parseInt(num),
                                        dno: params.dno,
                                        dtype: params.dtype,
                                    })
                                }
                            />
                            {/* <button
                                className="button"
                                onClick={
                                }
                            >
                                <EjectIcon />
                            </button> */}
                        </div>
                        <div className="params-select">
                            <Select
                                isSearchable={false}
                                styles={{
                                    container: css => ({
                                        ...css,
                                        fontSize: '0.875rem',
                                        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                        padding: '5px 8px',
                                    }),
                                    control: css => ({
                                        ...css,
                                        background:
                                            params.admin_status === 'blocked'
                                                ? '#969696'
                                                : '#00b6b6',
                                    }),
                                    singleValue: css => ({
                                        ...css,
                                        color: 'black',
                                    }),
                                }}
                                options={[
                                    {
                                        value: 'blocked',
                                    },
                                    {
                                        value: 'active',
                                    },
                                ]}
                                value={{ value: params.admin_status }}
                                getOptionLabel={option => option.value}
                                getOptionValue={option => option.value}
                                onChange={({ value }) =>
                                    updateAdminStatus({ admin_status: value, dno: params.dno })
                                }
                            />
                            <Select
                                isSearchable={false}
                                styles={{
                                    container: css => ({
                                        ...css,
                                        fontSize: '0.875rem',
                                        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                        padding: '5px 8px',
                                    }),
                                    control: css => ({
                                        ...css,
                                        color: 'black',
                                        background:
                                            params.conflict_status === 'not_confirmed'
                                                ? '#df0000'
                                                : '#1eb000',
                                    }),
                                    singleValue: css => ({
                                        ...css,
                                        color: 'black',
                                    }),
                                }}
                                options={[
                                    {
                                        value: 'not_confirmed',
                                    },
                                    {
                                        value: 'operational',
                                    },
                                ]}
                                value={{ value: params.conflict_status }}
                                getOptionLabel={option => option.value}
                                getOptionValue={option => option.value}
                                onChange={({ value }) =>
                                    updateConflictStatus({
                                        conflict_status: value,
                                        dno: params.dno,
                                    })
                                }
                            />
                            {/* <div className={`admin_status row_item ${params.admin_status === 'blocked' ? 'blocked' : 'active'}`}>{params.admin_status} </div> */}
                            {/* <div className={`conflict_status row_item ${params.conflict_status === 'not_confirmed' ? 'not_confirmed' : 'operational'}`}>{params.conflict_status}</div> */}
                        </div>
                    </div>
                </div>
            );
        else
            return (
                <div
                    style={{ padding: '10px', boxSizing: 'border-box' }}
                    className="powerbank no_bank"
                    id={'powerbank_' + num}
                >
                    <div>Слот: {num}</div>
                </div>
            );
    }
}

Slot.propTypes = {
    params: PropTypes.object,
    num: PropTypes.string,
    sno: PropTypes.string,
    extract: PropTypes.func,
    updateAdminStatus: PropTypes.func,
    updateConflictStatus: PropTypes.func,
};

export default Slot;
