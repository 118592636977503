import React from 'react';
import { Button, Dialog, DialogTitle } from '@material-ui/core';
import PassForm from './PassForm';
import { connect } from 'react-redux';
import { showNotification } from 'react-admin';
import config from '../config';
const env = process.env.REACT_APP_CUSTOM_NODE_ENV;

class ChangePass extends React.Component {
    state = {
        open: false,
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = e => {
        this.setState({ open: false });
    };

    handleSubmit = values => {
        const { record, showNotification } = this.props;
        const pass = values.password;
        const URL = config[env].url;
        const token = localStorage.getItem('token');
        const url = `${URL}iam/employees/change-pass`;
        showNotification('Действие обрабатывается');
        fetch(url, {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
            method: 'PUT',
            body: JSON.stringify({ id: record.user_id, pass }),
        })
            .then(response => {
                response.json().then(data => {
                    if (response.status === 200) {
                        showNotification('Успех');
                    } else {
                        showNotification('Ошибка: что-то пошло не так', 'warning');
                    }
                });
            })
            .catch(e => {
                console.error(e);
                showNotification('Ошибка: что-то пошло не так', 'warning');
            });
        this.setState({ open: false });
    };

    render() {
        return (
            <div>
                <Button
                    onClick={this.handleClickOpen}
                    variant="contained"
                    color="primary"
                >
                    Изменить пароль
                </Button>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">Введите новый пароль</DialogTitle>
                    <PassForm
                        onSubmit={this.handleSubmit}
                        handleClose={this.handleClose}
                    />
                </Dialog>
            </div>
        );
    }
}

export default connect(undefined, { showNotification })(ChangePass);
