import React from 'react';
import { ArrayInput, Edit, required, SimpleForm, SimpleFormIterator, TextInput } from 'react-admin';
import { ToolbarSaveAllDeleteAdmin } from '../utils';

const { ipOrHostValidation, urlValidation } = require('../customValidations/index');

export const EditPartnerService = ({ permissions, ...props }) => {
    return (
        <Edit {...props}>
            <SimpleForm toolbar={<ToolbarSaveAllDeleteAdmin permissions={permissions} />}>
                <TextInput
                    label="Имя"
                    source="name"
                    validate={[required()]}
                />

                <TextInput
                    label="UUID"
                    source="uuid"
                    disabled={true}
                />

                <TextInput
                    label="Описание"
                    source="description"
                />

                <TextInput
                    label="Владелец станций"
                    source="holder_type"
                />

                {/*//TODO*/}
                {/*<ReferenceInput*/}
                {/*    label="Владелец станций"*/}
                {/*    source="holder_type"*/}
                {/*    reference="station_holders"*/}
                {/*    allowEmpty*/}
                {/*>*/}
                {/*    <SelectInput*/}
                {/*        optionText='type'*/}
                {/*    />*/}
                {/*</ReferenceInput>*/}

                <ArrayInput
                    label="Разрешенные хосты"
                    source="hosts"
                >
                    <SimpleFormIterator>
                        <TextInput
                            source="host"
                            validate={[required(), ipOrHostValidation]}
                        />
                    </SimpleFormIterator>
                </ArrayInput>

                <TextInput
                    label="Callback URL"
                    source="callback_url"
                    validate={[required(), urlValidation]}
                />
            </SimpleForm>
        </Edit>
    );
};
