import React from 'react';
import {
    BooleanField,
    CardActions,
    Create,
    Datagrid,
    DateField,
    Edit,
    Filter,
    List,
    number,
    NumberInput,
    ReferenceField,
    required,
    SelectField,
    SelectInput,
    Show,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
} from 'react-admin';
import { DateTimeInput } from 'react-admin-date-inputs';
import CloseSession from './closeSession/CloseSession';
import {
    BulkDeleteButtonAdmin,
    CustomPagination,
    isEmployee,
    isGeEmployee,
    ToolbarSaveAllDeleteAdmin,
} from './utils';
import { tariffOptionsSessions } from './constants/tariffs';
import {
    AddServiceSession,
    EditServiceSession,
    PartnerServiceSessionsList,
    ShowServiceSession,
} from './listServiceSessions';

const SessionActions = ({ data, permissions, resource }) => (
    <CardActions
        submitOnEnter={false}
        style={{ zIndex: 2, display: 'inline-block', float: 'right' }}
    >
        {isGeEmployee(permissions) && (
            <CloseSession
                record={data}
                resource={resource}
            />
        )}
    </CardActions>
);

const SessionsFilter = props => (
    <Filter {...props}>
        <SelectInput
            label="Поле"
            source="field"
            choices={[
                { id: 'id', name: 'Id сессии' },
                { id: 'user_id', name: 'Id пользователя' },
                { id: 'status', name: 'Статус' },
                { id: 'start_time', name: 'Время начала' },
                { id: 'tariff', name: 'Тариф' },
                { id: 'dno', name: 'Dno' },
                { id: 'pb_return_time', name: 'Время возвращения пб' },
                { id: 'close_cause', name: 'Причина завершения' },
                { id: 'close_time', name: 'Время завершения' },
                { id: 'sno_start', name: 'Начальная станция' },
                { id: 'location_start', name: 'Начальная локация' },
                { id: 'sno_end', name: 'Конечная станция' },
                { id: 'location_end', name: 'Конечная локация' },
                { id: 'close_cause', name: 'Причина завершения' },
                { id: 'step', name: 'Шаг тарификации' },
                { id: 'paid_suc', name: 'Успешные платежи' },
                { id: 'paid_unsuc', name: 'Неуспешные платежи' },
                { id: 'amount_to_pay', name: 'Сумма ближайшего платежа' },
                { id: 'time_to_pay', name: 'Время ближайшего платежа' },
                { id: 'payment', name: 'Платежная система' },
                { id: 'first_session', name: 'Первая сессия' },
                { id: 'created', name: 'Время создания' },
                { id: 'updated', name: 'Последнее обновление' },
            ]}
            alwaysOn
        />
        <TextInput
            label="Поиск"
            source="q"
            alwaysOn
        />
        <DateTimeInput
            source="after"
            label="Сессии после"
            options={{ format: 'dd.MM.YYYY HH:mm', ampm: false }}
        />
        <DateTimeInput
            source="before"
            label="Сессии до"
            options={{ format: 'dd.MM.YYYY HH:mm', ampm: false }}
        />
        {/* <BooleanInput source="first_session" label="Первая сессия"/> */}
    </Filter>
);

const СloseCauseField = ({ record = {}, source }) => {
    switch (record[source]) {
        case 'closed_by_timer': {
            return <span>Закрыта по таймеру</span>;
        }
        case 'closed_unsuc_pay': {
            return <span>Проблемы с оплатой</span>;
        }
        case 'closed_unsuc_deposit': {
            return <span>Проблемы с оплатой депозита</span>;
        }
        case 'closed_unsuc_extract': {
            return <span>Проблемы со станцией</span>;
        }
        case 'closed_unsuc_noreply': {
            return <span>Cтанция не ответила</span>;
        }
        case 'closed_by_admin': {
            return <span>Закрыта администратором</span>;
        }
        case 'closed_with_return': {
            return <span>Закрыта по возврату ПБ</span>;
        }
        case 'closed_without_return': {
            return <span>Закрыта без возврата ПБ</span>;
        }
        case 'closed_without_return_fine': {
            return <span>Закрыта без возврата ПБ досрочно</span>;
        }
        default: {
            return <span>{record[source]}</span>;
        }
    }
};

export const SessionsList = ({ permissions, ...props }) =>
    permissions === 'partner_service' ? (
        PartnerServiceSessionsList({ permissions, ...props })
    ) : (
        <List
            {...props}
            filters={<SessionsFilter />}
            sort={{ field: 'id', order: 'DESC' }}
            bulkActionButtons={<BulkDeleteButtonAdmin permissions={permissions} />}
            pagination={<CustomPagination />}
        >
            <Datagrid rowClick="edit">
                <TextField
                    label="Id сессии"
                    source="id"
                />
                {isEmployee(permissions) && (
                    <TextField
                        label="Id пользователя"
                        source="user_id"
                    />
                )}
                <TextField
                    label="Статус"
                    source="status"
                />
                <DateField
                    label="Время начала"
                    source="start_time"
                    showTime
                    locales="ru-RU"
                />
                <SelectField
                    label="Тариф"
                    source="tariff"
                    choices={tariffOptionsSessions}
                />
                <SelectField
                    label="Tele2"
                    source="tele2_code"
                    choices={[
                        { id: 'bp_ok', name: 'Оплаченный ТП' },
                        { id: 'bp_have_service', name: 'Есть подписка' },
                        { id: 'bp_not_tele2', name: 'Не Tele2' },
                        { id: 'bp_wrong_tp', name: 'Без ТП и подписки' },
                        { id: 'bp_wrong_params', name: 'Внутренняя ошибка' },
                        { id: 'bp_internal_error', name: 'Служебная ошибка' },
                    ]}
                    allowEmpty
                />
                <TextField
                    label="Dno"
                    source="dno"
                />
                <DateField
                    label="Время возвращения пб"
                    source="pb_return_time"
                    showTime
                    locales="ru-RU"
                />
                <СloseCauseField
                    label="Причина завершения"
                    source="close_cause"
                />
                <DateField
                    label="Время завершения"
                    source="close_time"
                    showTime
                    locales="ru-RU"
                />
                <TextField
                    label="Начальная станция"
                    source="sno_start"
                />
                <TextField
                    label="Id стартовой локации"
                    source="location_start"
                />
                <ReferenceField
                    label="Стартовая локация"
                    source="location_start"
                    reference="locations"
                    linkType={false}
                    allowEmpty
                >
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField
                    label="Адрес стартовой локации"
                    source="location_start"
                    reference="locations"
                    linkType={false}
                    allowEmpty
                >
                    <TextField source="address" />
                </ReferenceField>
                <TextField
                    label="Конечная станция"
                    source="sno_end"
                />
                <TextField
                    label="Id конечная локации"
                    source="location_end"
                />
                <ReferenceField
                    label="Конечная локация"
                    source="location_end"
                    reference="locations"
                    linkType={false}
                    allowEmpty
                >
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField
                    label="Адрес конечной локации"
                    source="location_end"
                    reference="locations"
                    linkType={false}
                    allowEmpty
                >
                    <TextField source="address" />
                </ReferenceField>
                <TextField
                    label="Шаг тарификации"
                    source="step"
                />
                <TextField
                    label="Успешные платежи"
                    source="paid_suc"
                />
                <TextField
                    label="Неуспешные платежи"
                    source="paid_unsuc"
                />
                <TextField
                    label="Сумма ближайшего платежа"
                    source="amount_to_pay"
                />
                <DateField
                    label="Время ближайшего платежа"
                    source="time_to_pay"
                    showTime
                    locales="ru-RU"
                />
                <TextField
                    label="Сумма депозита"
                    source="deposit_amount"
                />
                <TextField
                    label="Оплачено депозита"
                    source="deposit_paid"
                />
                <TextField
                    label="Возвращено депозита"
                    source="deposit_returned"
                />
                <TextField
                    label="Платежная система"
                    source="payment"
                />
                <BooleanField
                    label="Первая сессия"
                    source="first_session"
                />
                <DateField
                    label="Время создания"
                    source="created"
                    showTime
                    locales="ru-RU"
                />
                <DateField
                    label="Последнее обновление"
                    source="updated"
                    showTime
                    locales="ru-RU"
                />
            </Datagrid>
        </List>
    );

export const AddSession = ({ permissions, ...props }) =>
    permissions === 'partner_service' ? (
        AddServiceSession({ permissions, ...props })
    ) : (
        <Create {...props}>
            <SimpleForm>
                <NumberInput
                    label="Id пользователя"
                    source="user_id"
                    validate={number()}
                />
                <SelectInput
                    label="Статус"
                    source="status"
                    choices={[
                        { id: 'active', name: 'active' },
                        { id: 'preparation', name: 'preparation' },
                        { id: 'waiting_station', name: 'waiting_station' },
                        { id: 'closed', name: 'closed' },
                    ]}
                    validate={required()}
                />
                <DateTimeInput
                    label="Время начала"
                    source="start_time"
                    options={{ ampm: false }}
                />
                <SelectInput
                    label="Тариф"
                    source="tariff"
                    choices={tariffOptionsSessions}
                />
                <SelectInput
                    label="Tele2"
                    source="tele2_code"
                    choices={[
                        { id: 'bp_ok', name: 'Оплаченный ТП' },
                        { id: 'bp_have_service', name: 'Есть подписка' },
                        { id: 'bp_not_tele2', name: 'Не Tele2' },
                        { id: 'bp_wrong_tp', name: 'Без ТП и подписки' },
                        { id: 'bp_wrong_params', name: 'Внутренняя ошибка' },
                        { id: 'bp_internal_error', name: 'Служебная ошибка' },
                    ]}
                    allowEmpty
                />
                <TextInput
                    label="Dno"
                    source="dno"
                />
                <DateTimeInput
                    label="Время возвращения пб"
                    source="pb_return_time"
                    options={{ ampm: false }}
                />
                <SelectInput
                    label="Причина завершения"
                    source="close_cause"
                    choices={[
                        { id: 'closed_with_return', name: 'Закрыта по возврату ПБ' },
                        { id: 'closed_without_return', name: 'Закрыта без возврата ПБ' },
                        {
                            id: 'closed_without_return_fine',
                            name: 'Закрыта без возврата ПБ досрочно',
                        },
                        { id: 'closed_by_admin', name: 'Закрыта администратором' },
                        { id: 'closed_by_timer', name: 'Закрыта по таймеру' },
                        { id: 'closed_unsuc_pay', name: 'Проблемы с оплатой' },
                        { id: 'closed_unsuc_extract', name: 'Проблемы со станцией' },
                    ]}
                    allowEmpty
                />
                <DateTimeInput
                    label="Время завершения"
                    source="close_time"
                    options={{ ampm: false }}
                />
                <TextInput
                    label="Начальная станция"
                    source="sno_start"
                />
                <NumberInput
                    label="Начальная локация"
                    source="location_start"
                />
                <TextInput
                    label="Конечная станция"
                    source="sno_end"
                />
                <NumberInput
                    label="Конечная локация"
                    source="location_end"
                />
                <NumberInput
                    label="Шаг тарификации"
                    source="step"
                />
                <NumberInput
                    label="Успешные платежи"
                    source="paid_suc"
                />
                <NumberInput
                    label="Неуспешные платежи"
                    source="paid_unsuc"
                />
                <NumberInput
                    label="Сумма депозита"
                    source="deposit_amount"
                />
                <NumberInput
                    label="Оплачено депозита"
                    source="deposit_paid"
                />
                <NumberInput
                    label="Возвращено депозита"
                    source="deposit_returned"
                />
                <TextInput
                    label="Платежная система"
                    source="payment"
                />
                <NumberInput
                    label="Сумма ближайшего платежа"
                    source="amount_to_pay"
                />
                <DateTimeInput
                    label="Время ближайшего платежа"
                    source="time_to_pay"
                    options={{ ampm: false }}
                />
            </SimpleForm>
        </Create>
    );

export const EditSession = ({ permissions, ...props }) =>
    permissions === 'partner_service' ? (
        EditServiceSession({ permissions, ...props })
    ) : (
        <Edit
            {...props}
            submitOnEnter={false}
            actions={<SessionActions permissions={permissions} />}
        >
            <SimpleForm
                submitOnEnter={false}
                toolbar={<ToolbarSaveAllDeleteAdmin permissions={permissions} />}
            >
                <TextInput
                    disabled
                    label="Id сессии"
                    source="id"
                />
                <NumberInput
                    label="Id пользователя"
                    source="user_id"
                    validate={number()}
                />
                <SelectInput
                    label="Статус"
                    source="status"
                    choices={[
                        { id: 'active', name: 'active' },
                        { id: 'preparation', name: 'preparation' },
                        { id: 'waiting_station', name: 'waiting_station' },
                        { id: 'closed', name: 'closed' },
                    ]}
                    validate={required()}
                />
                <DateTimeInput
                    label="Время начала"
                    source="start_time"
                    options={{ format: 'dd.MM.YYYY HH:mm', ampm: false }}
                />
                <SelectInput
                    label="Тариф"
                    source="tariff"
                    choices={tariffOptionsSessions}
                />
                <SelectInput
                    label="Tele2"
                    source="tele2_code"
                    choices={[
                        { id: 'bp_ok', name: 'Оплаченный ТП' },
                        { id: 'bp_have_service', name: 'Есть подписка' },
                        { id: 'bp_not_tele2', name: 'Не Tele2' },
                        { id: 'bp_wrong_tp', name: 'Без ТП и подписки' },
                        { id: 'bp_wrong_params', name: 'Внутренняя ошибка' },
                        { id: 'bp_internal_error', name: 'Служебная ошибка' },
                    ]}
                    disabled
                    allowEmpty
                />
                <TextInput
                    label="Dno"
                    source="dno"
                />
                <DateTimeInput
                    label="Время возвращения пб"
                    source="pb_return_time"
                    options={{ format: 'dd.MM.YYYY HH:mm', ampm: false }}
                />
                <SelectInput
                    label="Причина завершения"
                    source="close_cause"
                    choices={[
                        { id: 'closed_with_return', name: 'Закрыта по возврату ПБ' },
                        { id: 'closed_without_return', name: 'Закрыта без возврата ПБ' },
                        {
                            id: 'closed_without_return_fine',
                            name: 'Закрыта без возврата ПБ досрочно',
                        },
                        { id: 'closed_by_admin', name: 'Закрыта администратором' },
                        { id: 'closed_by_timer', name: 'Закрыта по таймеру' },
                        { id: 'closed_unsuc_pay', name: 'Проблемы с оплатой' },
                        { id: 'closed_unsuc_deposit', name: 'Проблемы с депозитом' },
                        { id: 'closed_unsuc_extract', name: 'Проблемы со станцией' },
                        { id: 'closed_unsuc_noreply', name: 'Cтанция не ответила' },
                    ]}
                    allowEmpty
                />
                <DateTimeInput
                    label="Время завершения"
                    source="close_time"
                    options={{ format: 'dd.MM.YYYY HH:mm', ampm: false }}
                />
                <TextInput
                    label="Начальная станция"
                    source="sno_start"
                />
                <NumberInput
                    label="Начальная локация"
                    source="location_start"
                />
                <TextInput
                    label="Конечная станция"
                    source="sno_end"
                />
                <NumberInput
                    label="Конечная локация"
                    source="location_end"
                />
                <NumberInput
                    label="Шаг тарификации"
                    source="step"
                />
                <NumberInput
                    disabled
                    label="Успешные платежи"
                    source="paid_suc"
                />
                <NumberInput
                    disabled
                    label="Неуспешные платежи"
                    source="paid_unsuc"
                />
                <NumberInput
                    label="Сумма ближайшего платежа"
                    source="amount_to_pay"
                />
                <DateTimeInput
                    label="Время ближайшего платежа"
                    source="time_to_pay"
                    options={{ format: 'dd.MM.YYYY HH:mm', ampm: false }}
                />
                <NumberInput
                    disabled
                    label="Сумма депозита"
                    source="deposit_amount"
                />
                <NumberInput
                    disabled
                    label="Оплачено депозита"
                    source="deposit_paid"
                />
                <NumberInput
                    disabled
                    label="Возвращено депозита"
                    source="deposit_returned"
                />
                <TextInput
                    disabled
                    label="Платежная система"
                    source="payment"
                />
                <TextInput
                    disabled
                    label="Время создания"
                    source="created"
                />
                <TextInput
                    disabled
                    label="Последнее обновление"
                    source="updated"
                />
            </SimpleForm>
        </Edit>
    );

export const ShowSession = ({ permissions, ...props }) =>
    permissions === 'partner_service' ? (
        ShowServiceSession({ permissions, ...props })
    ) : (
        <Show
            {...props}
            submitOnEnter={false}
            actions={<SessionActions permissions={permissions} />}
        >
            <SimpleShowLayout>
                <TextField
                    label="Id сессии"
                    source="id"
                />
                <TextField
                    label="Id пользователя"
                    source="user_id"
                />
                <SelectField
                    label="Статус"
                    source="status"
                    choices={[
                        { id: 'active', name: 'active' },
                        { id: 'preparation', name: 'preparation' },
                        { id: 'waiting_station', name: 'waiting_station' },
                        { id: 'closed', name: 'closed' },
                    ]}
                    validate={required()}
                />
                <DateField
                    label="Время начала"
                    source="start_time"
                    showTime
                    locales="ru-RU"
                />
                <SelectField
                    label="Тариф"
                    source="tariff"
                    choices={tariffOptionsSessions}
                />
                <SelectField
                    label="Tele2"
                    source="tele2_code"
                    choices={[
                        { id: 'bp_ok', name: 'Оплаченный ТП' },
                        { id: 'bp_have_service', name: 'Есть подписка' },
                        { id: 'bp_not_tele2', name: 'Не Tele2' },
                        { id: 'bp_wrong_tp', name: 'Без ТП и подписки' },
                        { id: 'bp_wrong_params', name: 'Внутренняя ошибка' },
                        { id: 'bp_internal_error', name: 'Служебная ошибка' },
                    ]}
                    disabled
                    allowEmpty
                />
                <TextField
                    label="Dno"
                    source="dno"
                />
                <DateField
                    label="Время возвращения пб"
                    source="pb_return_time"
                    showTime
                    locales="ru-RU"
                />
                <SelectField
                    label="Причина завершения"
                    source="close_cause"
                    choices={[
                        { id: 'closed_with_return', name: 'Закрыта по возврату ПБ' },
                        { id: 'closed_without_return', name: 'Закрыта без возврата ПБ' },
                        {
                            id: 'closed_without_return_fine',
                            name: 'Закрыта без возврата ПБ досрочно',
                        },
                        { id: 'closed_by_admin', name: 'Закрыта администратором' },
                        { id: 'closed_by_timer', name: 'Закрыта по таймеру' },
                        { id: 'closed_unsuc_pay', name: 'Проблемы с оплатой' },
                        { id: 'closed_unsuc_deposit', name: 'Проблемы с депозитом' },
                        { id: 'closed_unsuc_extract', name: 'Проблемы со станцией' },
                        { id: 'closed_unsuc_noreply', name: 'Cтанция не ответила' },
                    ]}
                    allowEmpty
                />
                <DateField
                    label="Время завершения"
                    source="close_time"
                    showTime
                    locales="ru-RU"
                />
                <TextField
                    label="Начальная станция"
                    source="sno_start"
                />
                <TextField
                    label="Начальная локация"
                    source="location_start"
                />
                <TextField
                    label="Конечная станция"
                    source="sno_end"
                />
                <TextField
                    label="Конечная локация"
                    source="location_end"
                />
                <TextField
                    label="Шаг тарификации"
                    source="step"
                />
                <TextField
                    label="Успешные платежи"
                    source="paid_suc"
                />
                <TextField
                    label="Неуспешные платежи"
                    source="paid_unsuc"
                />
                <TextField
                    label="Сумма ближайшего платежа"
                    source="amount_to_pay"
                />
                <DateField
                    label="Время ближайшего платежа"
                    source="time_to_pay"
                    showTime
                    locales="ru-RU"
                />
                <TextField
                    label="Сумма депозита"
                    source="deposit_amount"
                />
                <TextField
                    label="Оплачено депозита"
                    source="deposit_paid"
                />
                <TextField
                    label="Возвращено депозита"
                    source="deposit_returned"
                />
                <TextField
                    label="Платежная система"
                    source="payment"
                />
                <TextField
                    label="Время создания"
                    source="created"
                />
                <TextField
                    label="Последнее обновление"
                    source="updated"
                />
            </SimpleShowLayout>
        </Show>
    );
