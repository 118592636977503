import React, { Component } from 'react';
import { TextInput } from 'react-admin';
import { Button } from '@material-ui/core';
import Select from 'react-select';
import './LocalizedDataInput.css';
import { localeOptions } from '../constants/locales';

export class LocalizedDataInput extends Component {
    defaultItems = localeOptions.map(l => l.id);

    state = {
        initData: {},
        newItem: null,
        availableItems: [],
        usedItems: [],
    };

    async componentDidMount() {
        let { record, source, type } = this.props;
        if (!record[source]) {
            record[source] = {};
        }
        const usedItems = Object.keys(record[source]).filter(k => record[source][k] !== null);
        await this.setState({
            initData: record[source],
            usedItems: usedItems,
            availableItems: this.defaultItems.filter(
                v => type === 'edit' && !usedItems.some(vv => v == vv),
            ),
        });
    }

    async removeField(item) {
        let { record, source } = this.props;
        const index = this.state.usedItems.findIndex(v => v === item);
        if (index !== -1) {
            this.state.usedItems.splice(index, 1);
            this.state.availableItems.push(item);
            delete record[source][item];
            await this.setState(this.state);
        }
    }

    async setNewField(item) {
        let { record, source } = this.props;
        const index = this.state.availableItems.findIndex(v => v === item);
        if (index !== -1) {
            this.state.availableItems.splice(index, 1);
            this.state.usedItems.push(item);
            this.state.newItem = null;
            if (this.state.initData[item]) {
                record[source][item] = this.state.initData[item];
            }
            await this.setState(this.state);
        }
    }

    render() {
        let { label, record, source, type } = this.props;
        return (
            <div>
                {[
                    ...this.state.usedItems.map(locale => [
                        <div key={`${source}_${locale}`}>
                            <TextInput
                                label={`${label} в локали ${locale}`}
                                source={`${source}.${locale}`}
                                disabled={type !== 'edit'}
                                style={{ width: '256px' }}
                            />
                            <Button
                                style={{ marginBottom: '8px' }}
                                type="button"
                                label="Open"
                                onClick={e => this.removeField(locale)}
                                color="primary"
                            >
                                -
                            </Button>
                        </div>,
                        // <br/>
                    ]),
                ]}
                {this.state.availableItems.length ? (
                    <div className="localized-data-select-new">
                        <Select
                            isSearchable={false}
                            styles={{
                                control: css => ({
                                    ...css,
                                    width: '292px',
                                }),
                                menu: css => ({
                                    ...css,
                                    width: '292px',
                                }),
                                container: css => ({
                                    ...css,
                                    fontSize: '0.875rem',
                                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                }),
                                singleValue: css => ({
                                    ...css,
                                    color: 'black',
                                }),
                            }}
                            options={this.state.availableItems.map(v => ({
                                value: v,
                            }))}
                            value={this.state.newItem ? { value: this.state.newItem } : null}
                            getOptionLabel={option => option.value}
                            getOptionValue={option => option.value}
                            onChange={({ value }) => this.setNewField(value)}
                            placeholder={`Добавить '${label}' по локалям`}
                        />
                    </div>
                ) : null}
            </div>
        );
    }
}
