import { SelectInput } from 'react-admin';
import React from 'react';

const CategoryTypeList = category => {
    switch (category) {
        case 'Общепит':
            return (
                <SelectInput
                    label="Тип"
                    source="type"
                    choices={[
                        { id: 'Ресторан', name: 'Ресторан' },
                        { id: 'Кафе', name: 'Кафе' },
                        { id: 'Кофейня', name: 'Кофейня' },
                        { id: 'Бар', name: 'Бар' },
                        { id: 'Паб', name: 'Паб' },
                        { id: 'Клуб', name: 'Клуб' },
                        { id: 'Кальянная', name: 'Кальянная' },
                        { id: 'Стрит фуд', name: 'Стрит фуд' },
                        { id: 'Рынок', name: 'Рынок' },
                        { id: 'Другое', name: 'Другое' },
                    ]}
                    allowEmpty
                />
            );
        case 'Путешествия':
            return (
                <SelectInput
                    label="Тип"
                    source="type"
                    choices={[
                        { id: 'Городской отель', name: 'Городской отель' },
                        { id: 'Загородный отель', name: 'Загородный отель' },
                        { id: 'Хостел', name: 'Хостел' },
                        { id: 'Горнолыжный отель', name: 'Горнолыжный отель' },
                        { id: 'Другое', name: 'Другое' },
                    ]}
                    allowEmpty
                />
            );
        case 'Спорт':
            return (
                <SelectInput
                    label="Тип"
                    source="type"
                    choices={[
                        { id: 'Фитнес клуб', name: 'Фитнес клуб' },
                        { id: 'Стадион', name: 'Стадион' },
                        { id: 'Каток', name: 'Каток' },
                        { id: 'Спортивная секция', name: 'Спортивная секция' },
                        { id: 'Школа танцев', name: 'Школа танцев' },
                        { id: 'Другое', name: 'Другое' },
                    ]}
                    allowEmpty
                />
            );
        case 'Красота и здоровье':
            return (
                <SelectInput
                    label="Тип"
                    source="type"
                    choices={[
                        { id: 'Салон Красоты', name: 'Салон Красоты' },
                        { id: 'Медцентр', name: 'Медцентр' },
                        { id: 'Баня', name: 'Баня' },
                        { id: 'Массажный салон', name: 'Массажный салон' },
                        { id: 'СПА', name: 'СПА' },
                        { id: 'Маникюр', name: 'Маникюр' },
                        { id: 'Парикмахерская', name: 'Парикмахерская' },
                        { id: 'Татусалон', name: 'Татусалон' },
                        { id: 'Другое', name: 'Другое' },
                    ]}
                    allowEmpty
                />
            );
        case 'Развлечения и досуг':
            return (
                <SelectInput
                    label="Тип"
                    source="type"
                    choices={[
                        { id: 'Кинотеатр', name: 'Кинотеатр' },
                        { id: 'Театр', name: 'Театр' },
                        { id: 'Музей', name: 'Музей' },
                        { id: 'Концертный Зал', name: 'Концертный Зал' },
                        { id: 'Парк', name: 'Парк' },
                        { id: 'ТРЦ', name: 'ТРЦ' },
                        { id: 'Бильярд', name: 'Бильярд' },
                        { id: 'Боулинг', name: 'Боулинг' },
                        { id: 'Картинг клуб', name: 'Картинг клуб' },
                        { id: 'Ночной клуб', name: 'Ночной клуб' },
                        { id: 'Другое', name: 'Другое' },
                    ]}
                    allowEmpty
                />
            );
        case 'Транспорт':
            return (
                <SelectInput
                    label="Тип"
                    source="type"
                    choices={[
                        { id: 'Автобус', name: 'Автобус' },
                        { id: 'Аэропорт', name: 'Аэропорт' },
                        { id: 'Метро', name: 'Метро' },
                        { id: 'Вокзал', name: 'Вокзал' },
                        { id: 'МЦК', name: 'МЦК' },
                        { id: 'МЦД', name: 'МЦД' },
                        { id: 'Электрички', name: 'Электрички' },
                        { id: 'Другое', name: 'Другое' },
                    ]}
                    allowEmpty
                />
            );
        case 'Разное':
            return (
                <SelectInput
                    label="Тип"
                    source="type"
                    choices={[
                        { id: 'Бизнес центры', name: 'Бизнес центры' },
                        { id: 'Высшее Учебное Заведение', name: 'Высшее Учебное Заведение' },
                        {
                            id: 'Дополнительное обучение (автошколы, языковые курсы и т.д.)',
                            name: 'Дополнительное обучение (автошколы, языковые курсы и т.д.)',
                        },
                        { id: 'Сервисный центр', name: 'Сервисный центр' },
                        { id: 'Магазин', name: 'Магазин' },
                        { id: 'Автосалон', name: 'Автосалон' },
                        { id: 'Другое', name: 'Другое' },
                    ]}
                    allowEmpty
                />
            );
        case 'Другое':
            return (
                <SelectInput
                    label="Тип"
                    source="type"
                    choices={[{ id: 'Другое', name: 'Другое' }]}
                    allowEmpty
                />
            );
        default:
            return (
                <SelectInput
                    label="Тип"
                    source="type"
                    choices={[]}
                    allowEmpty
                />
            );
    }
};

export default CategoryTypeList;
