import React from 'react';
import { Admin, Resource } from 'react-admin';
import dataProvider from './dataProvider';
import { AddStation, EditStation, StationsList } from './listStations';
import { AddSlot, EditSlot, SlotsList } from './listSlots';
import { AddDevice, DevicesList, EditDevice } from './listDevices';
import { AddAgent, AgentsList, EditAgent } from './listAgents';
import { AddAgentContract, AgentContractsList, EditAgentContract } from './listAgentContracts';
import { AddFranchisee, EditFranchisee, FranchiseeList } from './listFranchisee';
import { AddFFContract, EditFFContract, FFContractsList } from './listFFContracts';
import { AddPartner, EditPartner, PartnersList } from './listPartners';
import {
    AddPartnerContract,
    EditPartnerContract,
    PartnerContractsList,
} from './listPartnerContracts';
import { EditProfile, ProfilesList } from './listProfiles';
import { AddAdminUser, AdminUsersList, EditAdminUser } from './listAdminUsers';
import { AddSession, EditSession, SessionsList } from './listSessions';
import { AddPartnerService, EditPartnerService, PartnerServicesList } from './PartnerServices';
import {
    AddPartnerServiceContract,
    EditPartnerServiceContract,
    PartnerServiceContractsList,
} from './PartnerServiceContracts';
import {
    AddServiceSession,
    EditServiceSession,
    PartnerServiceSessionsList,
    ShowServiceSession,
} from './listServiceSessions';
import { OrdersList, ShowOrder } from './listOrders';
import { TransactionsCPList, TransactionsCPShow } from './listTransactionsCP';
import { TariffsList } from './listTariffs';
import { AgentReportsList } from './listAgentReports';
import { PartnerReportsList } from './listPartnerReports';
import { FranchiseeReportsList } from './listFranchiseeReports';
import { FranchiseeLocationsReportsList } from './listFranchiseeLocationsReports';
import { AdminLocationsReportsList } from './listAdminLocationsReports';
import { PartnerLocationsReportsList } from './listPartnerLocationsReports';
import { AgentLocationsReportsList } from './listAgentLocationsReports';
import { MismatchList } from './listMismatch';
import { TotalMismatchList } from './listTotalMismatch';
import { AddCardsBlacklist, CardsBlacklistList, EditCardsBlacklist } from './listCardsBlacklist';
import { StationsStatusList } from './listStationsStatus';
import { AddText, EditText, TextsList } from './listTexts';
import authProvider from './authProvider';
import russianMessages from 'ra-language-russian';
import Dashboard from './Dashboard';
import MyLayout from './MyLayout';
import test from './testStation/testStationReducers';
import charts from './charts/chartsReducers';
import customRoutes from './customRoutes';
import MyTheme from './MyTheme';
import MyLogin from './MyLogin';
import { isAdmin, isEmployee, isFranchisee, isGeEmployee, isPartnerService } from './utils';
import { TransactionsStripeList, TransactionsStripeShow } from './listTransactionsStripe';
import { StripeCustomersList } from './listStripeCustomers';
import { ProfilePaymentMethodsList } from './listProfilePaymentMethods';
import { PlayersList } from './listPlayers';
import { Tele2ChecksList, Tele2ChecksShow } from './listTele2Checks';
import { ProfileBonusHistoryList } from './listProfileBonusHistory';
import { ClientEventsList, ClientEventsShow } from './listClientEvents';
import {
    AddExternalPromo,
    EditExternalPromo,
    ExternalPromoList,
    ShowExternalPromo,
} from './listExternalPromo';
import { AddStationHolder, EditStationHolder, StationHoldersList } from './listStationHolders';
import { TransactionsPaymeList, TransactionsPaymeShow } from './listTransactionsPayme';
import { AddLocation, EditLocation, ListLocations, ShowLocation } from './Locations';
import { ExternalProfilesList } from './ExternalProfiles';

const messages = {
    ru: russianMessages,
};
messages.ru.ra.notification.updated = 'Элемент обновляется';
const i18nProvider = locale => messages[locale];

const App = () => (
    <Admin
        theme={MyTheme}
        customRoutes={customRoutes}
        customReducers={{ test, charts }}
        appLayout={MyLayout}
        locale="ru"
        i18nProvider={i18nProvider}
        dataProvider={dataProvider}
        authProvider={authProvider}
        dashboard={Dashboard}
        loginPage={MyLogin}
    >
        {permissions => {
            return [
                <Resource
                    key="stations"
                    name="stations"
                    list={StationsList}
                    edit={
                        isFranchisee(permissions) || isPartnerService(permissions)
                            ? EditStation
                            : null
                    }
                    create={isEmployee(permissions) ? AddStation : null}
                />,
                <Resource
                    key="slots"
                    name="slots"
                    list={SlotsList}
                    edit={EditSlot}
                    create={AddSlot}
                />,
                <Resource
                    key="devices"
                    name="devices"
                    list={DevicesList}
                    edit={EditDevice}
                    create={AddDevice}
                />,
                <Resource
                    key="partners"
                    name="partners"
                    list={PartnersList}
                    edit={isFranchisee(permissions) ? EditPartner : null}
                    create={isFranchisee(permissions) ? AddPartner : null}
                />,
                <Resource
                    key="partner_contracts"
                    name="partner_contracts"
                    list={PartnerContractsList}
                    edit={permissions !== 'agent' ? EditPartnerContract : null}
                    create={permissions !== 'agent' ? AddPartnerContract : null}
                />,
                <Resource
                    key="agents"
                    name="agents"
                    list={AgentsList}
                    edit={permissions !== 'agent' ? EditAgent : null}
                    create={permissions !== 'agent' ? AddAgent : null}
                />,
                <Resource
                    key="agent_contracts"
                    name="agent_contracts"
                    list={AgentContractsList}
                    edit={permissions !== 'agent' ? EditAgentContract : null}
                    create={permissions !== 'agent' ? AddAgentContract : null}
                />,
                <Resource
                    key="franchisee"
                    name="franchisee"
                    list={FranchiseeList}
                    edit={isAdmin(permissions) ? EditFranchisee : null}
                    create={isGeEmployee(permissions) ? AddFranchisee : null}
                />,
                <Resource
                    key="ff_contracts"
                    name="ff_contracts"
                    list={FFContractsList}
                    edit={isEmployee(permissions) ? EditFFContract : null}
                    create={isGeEmployee(permissions) ? AddFFContract : null}
                />,
                <Resource
                    key="partner_services"
                    name="partner_services"
                    list={permissions === 'admin' ? PartnerServicesList : null}
                    edit={permissions === 'admin' ? EditPartnerService : null}
                    create={permissions === 'admin' ? AddPartnerService : null}
                />,
                <Resource
                    key="partner_service_contracts"
                    name="partner_service_contracts"
                    list={permissions === 'admin' ? PartnerServiceContractsList : null}
                    edit={permissions === 'admin' ? EditPartnerServiceContract : null}
                    create={permissions === 'admin' ? AddPartnerServiceContract : null}
                />,
                <Resource
                    key="locations"
                    name="locations"
                    list={ListLocations}
                    show={permissions !== 'agent' ? ShowLocation : null}
                    edit={
                        permissions !== 'agent' && permissions !== 'partner' ? EditLocation : null
                    }
                    create={
                        permissions !== 'agent' && permissions !== 'partner' ? AddLocation : null
                    }
                />,
                <Resource
                    key="profiles"
                    name="profiles"
                    list={ProfilesList}
                    edit={EditProfile}
                />,
                <Resource
                    key="external-profiles"
                    name="external-profiles"
                    list={ExternalProfilesList}
                />,
                <Resource
                    key="players"
                    name="players"
                    list={PlayersList}
                />,
                <Resource
                    key="sessions"
                    name="sessions"
                    list={SessionsList}
                    edit={isEmployee(permissions) ? EditSession : null}
                    create={isEmployee(permissions) ? AddSession : null}
                    show={ShowServiceSession}
                />,
                <Resource
                    key="partner_service_sessions"
                    name="partner_service_sessions"
                    list={PartnerServiceSessionsList}
                    edit={isAdmin(permissions) ? EditServiceSession : null}
                    create={isAdmin(permissions) ? AddServiceSession : null}
                />,
                <Resource
                    key="transactions_cp"
                    name="transactions_cp"
                    list={TransactionsCPList}
                    show={TransactionsCPShow}
                />,
                <Resource
                    key="transactions_stripe"
                    name="transactions_stripe"
                    list={TransactionsStripeList}
                    show={TransactionsStripeShow}
                />,
                <Resource
                    key="transactions_payme"
                    name="transactions_payme"
                    list={TransactionsPaymeList}
                    show={TransactionsPaymeShow}
                />,
                <Resource
                    key="tele2_checks"
                    name="tele2_checks"
                    list={Tele2ChecksList}
                    show={Tele2ChecksShow}
                />,
                <Resource
                    key="stripe_customers"
                    name="stripe_customers"
                    list={StripeCustomersList}
                />,
                <Resource
                    key="profile_payment_methods"
                    name="profile_payment_methods"
                    list={ProfilePaymentMethodsList}
                />,
                <Resource
                    key="profile_bonus_history"
                    name="profile_bonus_history"
                    list={ProfileBonusHistoryList}
                />,
                <Resource
                    key="orders"
                    name="orders"
                    list={OrdersList}
                    show={ShowOrder}
                />,
                <Resource
                    key="agent_reports"
                    name="agent_reports"
                    list={AgentReportsList}
                />,
                <Resource
                    key="partner_reports"
                    name="partner_reports"
                    list={PartnerReportsList}
                />,
                <Resource
                    key="franchisee_reports"
                    name="franchisee_reports"
                    list={FranchiseeReportsList}
                />,
                <Resource
                    key="franchisee_locations_reports"
                    name="franchisee_locations_reports"
                    list={FranchiseeLocationsReportsList}
                />,
                <Resource
                    key="admin_locations_reports"
                    name="admin_locations_reports"
                    list={AdminLocationsReportsList}
                />,
                <Resource
                    key="partner_locations_reports"
                    name="partner_locations_reports"
                    list={PartnerLocationsReportsList}
                />,
                <Resource
                    key="agent_locations_reports"
                    name="agent_locations_reports"
                    list={AgentLocationsReportsList}
                />,
                <Resource
                    key="admin_users"
                    name="admin_users"
                    list={AdminUsersList}
                    edit={isGeEmployee(permissions) ? EditAdminUser : null}
                    create={isGeEmployee(permissions) ? AddAdminUser : null}
                />,
                <Resource
                    key="tariffs"
                    name="tariffs"
                    list={TariffsList}
                />,
                <Resource
                    key="mismatch"
                    name="mismatch"
                    list={MismatchList}
                />,
                <Resource
                    key="total_mismatch"
                    name="total_mismatch"
                    list={TotalMismatchList}
                />,
                <Resource
                    key="cards_blacklist"
                    name="cards_blacklist"
                    list={CardsBlacklistList}
                    edit={isGeEmployee(permissions) ? EditCardsBlacklist : null}
                    create={isGeEmployee(permissions) ? AddCardsBlacklist : null}
                />,
                <Resource
                    key="stations_status"
                    name="stations_status"
                    list={StationsStatusList}
                />,
                <Resource
                    key="texts"
                    name="texts"
                    list={TextsList}
                    edit={isGeEmployee(permissions) ? EditText : null}
                    create={isGeEmployee(permissions) ? AddText : null}
                />,
                <Resource
                    key="client_events"
                    name="client_events"
                    list={isGeEmployee(permissions) ? ClientEventsList : null}
                    show={isGeEmployee(permissions) ? ClientEventsShow : null}
                />,
                <Resource
                    key="external_promo"
                    name="external_promo"
                    list={ExternalPromoList}
                    edit={isAdmin(permissions) ? EditExternalPromo : null}
                    create={isAdmin(permissions) ? AddExternalPromo : null}
                    show={isEmployee(permissions) ? ShowExternalPromo : null}
                />,
                <Resource
                    key="station_holders"
                    name="station_holders"
                    list={StationHoldersList}
                    edit={isAdmin(permissions) ? EditStationHolder : null}
                    create={isAdmin(permissions) ? AddStationHolder : null}
                />,
            ];
        }}
    </Admin>
);

export default App;
