import React from 'react';
import { List, Datagrid, TextField, TextInput, Filter, SelectInput } from 'react-admin';
import { CustomPagination, BulkDeleteButtonDisable } from './utils';

const TariffsFilter = props => (
    <Filter {...props}>
        <SelectInput
            label="Поле"
            source="field"
            choices={[
                { id: 'tariff', name: 'tariff' },
                { id: 'step', name: 'step' },
                { id: 'start_after_init', name: 'start_after_init' },
                { id: 'action', name: 'action' },
                { id: 'params', name: 'params' },
            ]}
            alwaysOn
        />
        <TextInput
            label="Поиск"
            source="q"
            alwaysOn
        />
    </Filter>
);

export const TariffsList = props => (
    <List
        {...props}
        filters={<TariffsFilter />}
        bulkActionButtons={<BulkDeleteButtonDisable />}
        pagination={<CustomPagination />}
    >
        <Datagrid rowClick="edit">
            <TextField
                label="Тариф"
                source="tariff"
            />
            <TextField
                label="Шаг"
                source="step"
            />
            <TextField
                label="Время после начала"
                source="start_after_init"
            />
            <TextField
                label="Действие"
                source="action"
            />
            <TextField
                label="Сумма"
                source="params"
            />
            <TextField
                label="Описание шага"
                source="description"
            />
            <TextField
                label="Название тарифа"
                source="tariff_name"
            />
            <TextField
                label="Описание тарифа 1"
                source="tariff_description1"
            />
            <TextField
                label="Описание тарифа 2"
                source="tariff_description2"
            />
        </Datagrid>
    </List>
);
