import React from 'react';
import {
    List,
    Create,
    Datagrid,
    TextField,
    SelectInput,
    DateField,
    SimpleForm,
    Edit,
    TextInput,
    Filter,
} from 'react-admin';
import { CustomPagination, BulkDeleteButtonAdmin, ToolbarSave } from './utils';

const CardsBlacklistFilter = props => (
    <Filter {...props}>
        <SelectInput
            label="Поле"
            source="field"
            choices={[
                { id: 'id', name: 'Id' },
                { id: 'card_number', name: 'Номер карты' },
                { id: 'created', name: 'Время создания' },
                { id: 'updated', name: 'Последнее обновление' },
            ]}
            alwaysOn
        />
        <TextInput
            label="Поиск"
            source="q"
            alwaysOn
        />
    </Filter>
);

export const CardsBlacklistList = ({ permissions, ...props }) => (
    <List
        {...props}
        filters={<CardsBlacklistFilter />}
        bulkActionButtons={<BulkDeleteButtonAdmin permissions={permissions} />}
        pagination={<CustomPagination />}
    >
        <Datagrid rowClick="edit">
            <TextField
                label="Id"
                source="id"
            />
            <TextField
                label="Номер карты"
                source="card_number"
            />
            <DateField
                label="Время создания"
                source="created"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Последнее обновление"
                source="updated"
                showTime
                locales="ru-RU"
            />
        </Datagrid>
    </List>
);

export const AddCardsBlacklist = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput
                label="Номер карты"
                source="card_number"
            />
        </SimpleForm>
    </Create>
);

export const EditCardsBlacklist = props => (
    <Edit {...props}>
        <SimpleForm toolbar={<ToolbarSave />}>
            <TextInput
                disabled
                label="Id"
                source="id"
            />
            <TextInput
                label="Номер карты"
                source="card_number"
            />
            <TextInput
                disabled
                label="Время создания"
                source="created"
            />
            <TextInput
                disabled
                label="Последнее обновление"
                source="updated"
            />
        </SimpleForm>
    </Edit>
);
