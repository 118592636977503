import { FormDataConsumer, SelectInput } from 'react-admin';
import React from 'react';
import { CategoryTypeList } from './index';

const TypeField = ({ ...props }) => {
    return (
        <FormDataConsumer>
            {({ formData, ...rest }) => formData && CategoryTypeList(formData.category)}
        </FormDataConsumer>
    );
};

export default TypeField;
