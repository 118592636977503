import React from 'react';
import { reduxForm } from 'redux-form';
import { TextInput } from 'react-admin';
import { Button, DialogActions, DialogContent } from '@material-ui/core';

const PassForm = props => {
    const { handleSubmit, handleClose } = props;

    return (
        <form onSubmit={handleSubmit}>
            <DialogContent>
                <TextInput
                    label="Пароль"
                    source="password"
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    color="primary"
                >
                    Отмена
                </Button>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                >
                    Изменить
                </Button>
            </DialogActions>
        </form>
    );
};

export default reduxForm({
    form: 'simple',
})(PassForm);
