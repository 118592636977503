import config from '../config';
import { showNotification } from 'react-admin';
import moment from 'moment';
const env = process.env.REACT_APP_CUSTOM_NODE_ENV;
const URL = config[env].url;
const permissions = localStorage.getItem('permissions');
let url;
if (permissions === 'franchisee' || permissions === 'tester') {
    url = `${URL}franchisee/`;
} else {
    url = `${URL}admin/`;
}

export const loadData = (dispatch, params) => {
    // console.log(data)
    // dispatch({
    //   type: "DATA_LOAD",
    //   payload: data
    // });
    // console.log(params);
    const startDate = params && params.startDate ? moment(params.startDate).toISOString() : null;
    const endDate = params && params.endDate ? moment(params.endDate).toISOString() : null;
    // console.log(params, startDate);
    const token = localStorage.getItem('token');
    const paramsS = new URLSearchParams({ startDate, endDate }).toString();
    // const paramsS = new URLSearchParams(params).toString();
    return fetch(`${url}charts/revenue?` + paramsS, {
        headers: { Authorization: `Bearer ${token}` },
    })
        .then(async res => {
            if (!res.ok) {
                dispatch(showNotification('Ошибка: что-то пошло не так', 'warning'));
            } else {
                const data = await res.json();
                dispatch({
                    type: 'DATA_LOAD',
                    payload: data,
                });
            }
        })
        .catch(err => {
            console.log(err);
        });
};

export const changeParams = (dispatch, params) => {
    // console.log(params)
    dispatch({
        type: 'CHANGE_PARAMS',
        payload: params,
    });
};
