import React, { Fragment } from 'react';
import { List, Datagrid, TextField, Filter } from 'react-admin';
import { DateInput } from 'react-admin-date-inputs';
import moment from 'moment';
import ExportButton from './reports/ExportButton';
import { CustomPagination } from './utils';

const AgentLocationsReportsFilter = props => {
    return (
        <Filter {...props}>
            <DateInput
                label="C"
                source="start_date"
                options={{ format: 'dd.MM.YYYY' }}
                isRequired={false}
                alwaysOn
            />
            <DateInput
                label="До"
                source="end_date"
                options={{ format: 'dd.MM.YYYY' }}
                isRequired={false}
                alwaysOn
            />
        </Filter>
    );
};

const BulkActionButtons = props => (
    <Fragment>
        <ExportButton
            label="Экспортировать"
            {...props}
        />
    </Fragment>
);

export const AgentLocationsReportsList = props => (
    <List
        {...props}
        filters={<AgentLocationsReportsFilter />}
        // pagination={false}
        pagination={<CustomPagination />}
        exporter={false}
        filterDefaultValues={{
            start_date: moment().startOf('month').toDate(),
            end_date: moment().startOf('day').toDate(),
        }}
        bulkActionButtons={<BulkActionButtons />}
    >
        <Datagrid rowClick="edit">
            <TextField
                label="Id локации"
                source="id"
            />
            <TextField
                label="Название"
                source="name"
            />
            <TextField
                label="Адрес"
                source="address"
            />
            <TextField
                label="Партнер"
                source="partner_name"
            />
            <TextField
                label="Количество сессий"
                source="sessions_count"
            />
            <TextField
                label="Выручка от локации"
                source="revenue"
            />
            <TextField
                label="Комиссия франчайзи"
                source="franchisee_commission"
            />
            <TextField
                label="Комиссия партнера"
                source="partner_commission"
            />
        </Datagrid>
    </List>
);
