import React from 'react';
import { List, Datagrid, TextField, DateField, TextInput, Filter, SelectInput } from 'react-admin';
import { CustomPagination, BulkDeleteButtonAdmin } from './utils';

const StationsStatusFilter = props => (
    <Filter {...props}>
        <SelectInput
            label="Поле"
            source="field"
            choices={[
                { id: 'sno', name: 'Sno' },
                { id: 'prev_status', name: 'Старый статус' },
                { id: 'next_status', name: 'Новый статус' },
                { id: 'created', name: 'Время создания' },
                { id: 'updated', name: 'Последнее обновление' },
            ]}
            alwaysOn
        />
        <TextInput
            label="Поиск"
            source="q"
            alwaysOn
        />
    </Filter>
);

export const StationsStatusList = ({ permissions, ...props }) => (
    <List
        {...props}
        filters={<StationsStatusFilter />}
        bulkActionButtons={<BulkDeleteButtonAdmin permissions={permissions} />}
        pagination={<CustomPagination />}
    >
        <Datagrid rowClick="edit">
            <TextField
                label="Sno"
                source="sno"
            />
            <TextField
                label="Старый статус"
                source="prev_status"
            />
            <TextField
                label="Новый статус"
                source="next_status"
            />
            <DateField
                label="Время создания"
                source="created"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Последнее обновление"
                source="updated"
                showTime
                locales="ru-RU"
            />
        </Datagrid>
    </List>
);
