import React from 'react';
import {
    ArrayField,
    Datagrid,
    DateField,
    Filter,
    List,
    SelectInput,
    Show,
    SimpleShowLayout,
    TextField,
    TextInput,
} from 'react-admin';
import { BulkDeleteButtonDisable, CustomPagination, isGeEmployee } from './utils';
import { CardActions } from '@material-ui/core';
import CancelTransactionButton from './transactionButton/CancelTransactionButton';

const TransactionsStripeActions = ({ data, permissions }) => (
    <CardActions
        submitOnEnter={false}
        style={{ zIndex: 2, display: 'inline-block', float: 'right' }}
    >
        {isGeEmployee(permissions) && (
            <CancelTransactionButton
                record={data}
                transaction_type={'stripe'}
            />
        )}
    </CardActions>
);

const TransactionsStripeFilter = props => (
    <Filter {...props}>
        <SelectInput
            label="Поле"
            source="field"
            choices={[
                { id: 'id', name: 'Id' },
                { id: 'stripe_id', name: 'Id в Stripe' },
                { id: 'order_id', name: 'Id заказа' },
                { id: 'stripe_customer_id', name: 'Id клиента' },
                { id: 'profile_payment_method_id', name: 'Id метода оплаты' },
                { id: 'amount', name: 'Сумма транзакции' },
                { id: 'currency', name: 'Валюта транзакции' },
                { id: 'email', name: 'Почта' },
                { id: 'description', name: 'Описание' },
                { id: 'status', name: 'Статус' },
                { id: 'capture_method', name: 'Тип захвата платежа' },
                { id: 'error_code', name: 'Код ошибки' },
                { id: 'stripe_created', name: 'Дата и время авторизации' },
                { id: 'stripe_canceled', name: 'Дата и время отмены' },
                { id: 'cancellation_reason', name: 'Причина отмены' },
                { id: 'created', name: 'Время создания' },
                { id: 'updated', name: 'Последнее обновление' },
            ]}
            alwaysOn
        />
        <TextInput
            label="Поиск"
            source="q"
            alwaysOn
        />
    </Filter>
);

export const TransactionsStripeList = props => (
    <List
        {...props}
        filters={<TransactionsStripeFilter />}
        bulkActionButtons={<BulkDeleteButtonDisable />}
        pagination={<CustomPagination />}
    >
        <Datagrid rowClick="show">
            <TextField
                label="Id"
                source="id"
            />
            <TextField
                label="Id в Stripe"
                source="stripe_id"
            />
            <TextField
                label="Id заказа"
                source="order_id"
            />
            <TextField
                label="Id клиента"
                source="stripe_customer_id"
            />
            <TextField
                label="Id метода оплаты"
                source="profile_payment_method_id"
            />
            <TextField
                label="Сумма транзакции"
                source="amount"
            />
            <TextField
                label="Оставшаяся сумма захвата"
                source="amount_capturable"
            />
            <TextField
                label="Валюта транзакции"
                source="currency"
            />
            <TextField
                label="Почта"
                source="email"
            />
            <TextField
                label="Описание"
                source="description"
            />
            <TextField
                label="Статус"
                source="status"
            />
            <TextField
                label="Тип захвата платежа"
                source="capture_method"
            />
            <TextField
                label="Код ошибки"
                source="error_code"
            />
            <DateField
                label="Дата и время авторизации"
                source="stripe_created"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Дата и время платежа"
                source="stripe_charge_created"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Дата и время отмены"
                source="stripe_canceled"
                showTime
                locales="ru-RU"
            />
            <TextField
                label="Причина отмены"
                source="cancellation_reason"
            />
            <DateField
                label="Время создания"
                source="created"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Последнее обновление"
                source="updated"
                showTime
                locales="ru-RU"
            />
        </Datagrid>
    </List>
);

export const TransactionsStripeShow = ({ permissions, ...props }) => (
    <Show
        {...props}
        actions={<TransactionsStripeActions permissions={permissions} />}
    >
        <SimpleShowLayout>
            <TextField
                label="Id"
                source="id"
            />
            <TextField
                label="Id в Stripe"
                source="stripe_id"
            />
            <TextField
                label="Id заказа"
                source="order_id"
            />
            <TextField
                label="Id клиента"
                source="stripe_customer_id"
            />
            <TextField
                label="Id метода оплаты"
                source="profile_payment_method_id"
            />
            <TextField
                label="Сумма транзакции"
                source="amount"
            />
            <TextField
                label="Оставшаяся сумма захвата"
                source="amount_capturable"
            />
            <TextField
                label="Валюта транзакции"
                source="currency"
            />
            <TextField
                label="Почта"
                source="email"
            />
            <TextField
                label="Описание"
                source="description"
            />
            <TextField
                label="Статус"
                source="status"
            />
            <TextField
                label="Тип захвата платежа"
                source="capture_method"
            />
            <TextField
                label="Код ошибки"
                source="error_code"
            />
            <DateField
                label="Дата и время авторизации"
                source="stripe_created"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Дата и время платежа"
                source="stripe_charge_created"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Дата и время отмены"
                source="stripe_canceled"
                showTime
                locales="ru-RU"
            />
            <TextField
                label="Причина отмены"
                source="cancellation_reason"
            />
            <DateField
                label="Время создания"
                source="created"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Последнее обновление"
                source="updated"
                showTime
                locales="ru-RU"
            />
            <ArrayField
                label="История статусов"
                source="status_history"
            >
                <Datagrid>
                    <TextField
                        label="Статус"
                        source="status"
                    />
                    <TextField
                        label="Код ошибки"
                        source="code"
                    />
                    <TextField
                        label="Причина отказа"
                        source="decline_code"
                    />
                    <TextField
                        label="Время создания в Stripe"
                        source="remote_created"
                    />
                    <TextField
                        label="Локальное время создания"
                        source="created"
                    />
                </Datagrid>
            </ArrayField>
        </SimpleShowLayout>
    </Show>
);
