import React from 'react';
import { List, Datagrid, TextField, DateField, TextInput, Filter, SelectInput } from 'react-admin';
import { CustomPagination, BulkDeleteButtonAdmin } from './utils';

const MismatchFilter = props => (
    <Filter {...props}>
        <SelectInput
            label="Поле"
            source="field"
            choices={[
                { id: 'dno', name: 'Зарядник' },
                { id: 'overwritten_by', name: 'Переписан' },
                { id: 'sno', name: 'Станция' },
                { id: 'position', name: 'Позиция' },
                { id: 'created', name: 'Время создания' },
                { id: 'updated', name: 'Последнее обновление' },
            ]}
            alwaysOn
        />
        <TextInput
            label="Поиск"
            source="q"
            alwaysOn
        />
    </Filter>
);

export const MismatchList = ({ permissions, ...props }) => (
    <List
        {...props}
        filters={<MismatchFilter />}
        bulkActionButtons={<BulkDeleteButtonAdmin permissions={permissions} />}
        pagination={<CustomPagination />}
    >
        <Datagrid rowClick="edit">
            <TextField
                label="Зарядник"
                source="dno"
            />
            <TextField
                label="Переписан"
                source="overwritten_by"
            />
            <TextField
                label="Станция"
                source="sno"
            />
            <TextField
                label="Позиция"
                source="position"
            />
            <DateField
                label="Время создания"
                source="created"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Последнее обновление"
                source="updated"
                showTime
                locales="ru-RU"
            />
        </Datagrid>
    </List>
);
