import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    ReferenceField,
    SelectInput,
    SimpleForm,
    Create,
    Edit,
    TextInput,
    ReferenceInput,
    Filter,
    required,
    number,
    minValue,
    maxValue,
} from 'react-admin';
import { DateInput } from 'react-admin-date-inputs';
import { withStyles } from '@material-ui/core/styles';
import {
    CustomPagination,
    BulkDeleteButtonAdmin,
    ToolbarSaveAllDeleteAdmin,
    stylesWide,
} from './utils';

const AgentContractsFilter = props => (
    <Filter {...props}>
        <SelectInput
            label="Поле"
            source="field"
            choices={[
                { id: 'id', name: 'Id договора' },
                { id: 'agent_id', name: 'Id агента' },
                { id: 'number_accounting', name: 'Номер договора в 1С' },
                { id: 'com_cond', name: 'Условия' },
                { id: 'other_cond', name: 'Прочие условия' },
                { id: 'issue_date', name: 'Дата заключения договора' },
                { id: 'start_date', name: 'Дата начала действия договора' },
                { id: 'end_date', name: 'Дата окончания действия договора' },
                { id: 'details', name: 'Детали' },
                { id: 'status', name: 'Статус' },
                { id: 'created', name: 'Время создания' },
                { id: 'updated', name: 'Последнее обновление' },
            ]}
            alwaysOn
        />
        <TextInput
            label="Поиск"
            source="q"
            alwaysOn
        />
    </Filter>
);

export const AgentContractsList = ({ permissions, ...props }) => (
    <List
        {...props}
        filters={<AgentContractsFilter />}
        bulkActionButtons={<BulkDeleteButtonAdmin permissions={permissions} />}
        pagination={<CustomPagination />}
    >
        <Datagrid rowClick="edit">
            <TextField
                label="Id договора"
                source="id"
            />
            <ReferenceField
                label="Агент"
                source="agent_id"
                reference="agents"
                linkType={false}
                allowEmpty
            >
                <TextField source="name" />
            </ReferenceField>
            <TextField
                label="Номер договора в 1С"
                source="number_accounting"
            />
            <TextField
                label="Условия"
                source="com_cond"
            />
            <TextField
                label="Прочие условия"
                source="other_cond"
            />
            <DateField
                label="Дата заключения договора"
                source="issue_date"
                locales="ru-RU"
            />
            <DateField
                label="Дата начала действия договора"
                source="start_date"
                locales="ru-RU"
            />
            <DateField
                label="Дата окончания действия договора"
                source="end_date"
                locales="ru-RU"
            />
            <TextField
                label="Детали"
                source="details"
            />
            <TextField
                label="Статус"
                source="status"
            />
            <DateField
                label="Время создания"
                source="created"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Последнее обновление"
                source="updated"
                showTime
                locales="ru-RU"
            />
        </Datagrid>
    </List>
);

export const AddAgentContract = withStyles(stylesWide)(({ classes, ...props }) => (
    <Create
        classes={classes}
        {...props}
    >
        <SimpleForm classes={classes}>
            <ReferenceInput
                perPage={10000}
                label="Агент"
                source="agent_id"
                reference="agents"
                className={classes.wide}
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput
                label="Номер договора в 1С"
                source="number_accounting"
                className={classes.wide}
            />
            <TextInput
                label="Условия"
                source="com_cond"
                validate={[required(), number(), minValue(0), maxValue(1)]}
            />
            <TextInput
                label="Прочие условия"
                source="other_cond"
            />
            <DateInput
                label="Дата заключения договора"
                source="issue_date"
                options={{ format: 'dd.MM.YYYY' }}
                className={classes.wide}
            />
            <DateInput
                label="Дата начала действия договора"
                source="start_date"
                options={{ format: 'dd.MM.YYYY' }}
                className={classes.wide}
            />
            <DateInput
                label="Дата окончания действия договора"
                source="end_date"
                options={{ format: 'dd.MM.YYYY' }}
                className={classes.wide}
            />
            <TextInput
                label="Детали"
                source="details"
                className={classes.wide}
            />
            <SelectInput
                label="Статус"
                source="status"
                choices={[
                    { id: 'active', name: 'active' },
                    { id: 'inactive', name: 'inactive' },
                ]}
                className={classes.wide}
            />
        </SimpleForm>
    </Create>
));

export const EditAgentContract = withStyles(stylesWide)(({ classes, permissions, ...props }) => (
    <Edit
        classes={classes}
        {...props}
    >
        <SimpleForm
            classes={classes}
            toolbar={<ToolbarSaveAllDeleteAdmin permissions={permissions} />}
        >
            <TextInput
                disabled
                label="Id договора"
                source="id"
                className={classes.wide}
            />
            <ReferenceInput
                perPage={10000}
                label="Агент"
                source="agent_id"
                reference="agents"
                className={classes.wide}
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput
                label="Номер договора в 1С"
                source="number_accounting"
                className={classes.wide}
            />
            <TextInput
                label="Условия"
                source="com_cond"
                className={classes.wide}
                validate={[required(), number(), minValue(0), maxValue(1)]}
            />
            <TextInput
                label="Прочие условия"
                source="other_cond"
                className={classes.wide}
            />
            <DateInput
                label="Дата заключения договора"
                source="issue_date"
                options={{ format: 'dd.MM.YYYY' }}
                className={classes.wide}
            />
            <DateInput
                label="Дата начала действия договора"
                source="start_date"
                options={{ format: 'dd.MM.YYYY' }}
                className={classes.wide}
            />
            <DateInput
                label="Дата окончания действия договора"
                source="end_date"
                options={{ format: 'dd.MM.YYYY' }}
                className={classes.wide}
            />
            <TextInput
                label="Детали"
                source="details"
                className={classes.wide}
            />
            <SelectInput
                label="Статус"
                source="status"
                choices={[
                    { id: 'active', name: 'active' },
                    { id: 'inactive', name: 'inactive' },
                ]}
                className={classes.wide}
            />
            <TextInput
                disabled
                label="Время создания"
                source="created"
                className={classes.wide}
            />
            <TextInput
                disabled
                label="Последнее обновление"
                source="updated"
                className={classes.wide}
            />
        </SimpleForm>
    </Edit>
));
