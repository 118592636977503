import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { Button } from '@material-ui/core';
import { SelectInput } from 'react-admin';

class Open extends Component {
    componentDidMount() {
        this.props.initialize({ sno: this.props.active });
    }

    componentDidUpdate(prevProps) {
        if (this.props.active !== prevProps.active) {
            this.props.initialize({ sno: this.props.active });
        }
    }

    render() {
        const { handleSubmit, capacity } = this.props;
        // const arr = [...Array(12).keys()]
        return (
            <form
                onSubmit={handleSubmit}
                style={{ display: 'flex', alignItems: 'end' }}
            >
                {/* <SelectInput style={{display: "inline-flex", width: '100px', paddingRight: '30px', marginTop: 0}} label="Sno станции" source="sno" choices={
          snoList.map(station => { return {id: station.sno, name: station.sno }})
        } /> */}
                {/* <SelectInput style={{display: "inline-flex", width: '100px', paddingRight: '30px'}} label="Позиция" source="position" choices={
          arr.map(pos => { return {id: pos+1, name: pos+1 }})
       } /> */}
                <SelectInput
                    style={{
                        display: 'inline-flex',
                        width: '100px',
                        paddingRight: '0px',
                        marginTop: 0,
                    }}
                    label="Позиция"
                    source="position"
                    choices={[...Array(capacity).keys()].map(pos => {
                        return { id: pos + 1, name: pos + 1 };
                    })}
                />

                {/* <NumberInput style={{marginTop: 0}} label="Позиция" source="position"/> */}
                <Button
                    style={{ marginBottom: '8px', marginLeft: '8px' }}
                    type="submit"
                    label="Open"
                    color="primary"
                >
                    Open
                </Button>
            </form>
        );
    }
}

export default reduxForm({
    form: 'simple',
})(Open);
