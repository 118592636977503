import React from 'react';
import { BulkDeleteButton, DeleteButton, Pagination, SaveButton, Toolbar } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

const isAdmin = permissions => {
    return permissions === 'admin' ? true : false;
};

const isGeEmployee = permissions => {
    return permissions === 'admin' || permissions === 'ge_employee' ? true : false;
};

const isEmployee = permissions => {
    return permissions === 'admin' || permissions === 'ge_employee' || permissions === 'employee'
        ? true
        : false;
};

const isFranchisee = permissions => {
    return permissions === 'admin' ||
        permissions === 'ge_employee' ||
        permissions === 'employee' ||
        permissions === 'franchisee'
        ? true
        : false;
};

const isPartner = permissions => {
    return permissions === 'partner' ? true : false;
};

const isAgent = permissions => {
    return permissions === 'agent' ? true : false;
};

const isPartnerService = permissions => {
    return permissions === 'partner_service' ? true : false;
};

// const isTester = (permissions) => {
//   return (permissions === 'admin' || permissions === 'ge_employee' || permissions === 'employee' || permissions === 'franchisee' || permissions === 'tester') ? true : false;
// }

export const CustomPagination = props => (
    <Pagination
        rowsPerPageOptions={[5, 10, 25, 50, 100, 500]}
        {...props}
    />
);

export const BulkDeleteButtonAdmin = props => {
    return isAdmin(props.permissions) ? (
        <BulkDeleteButton {...props} />
    ) : (
        <BulkDeleteButton
            {...props}
            disabled
        />
    );
};

export const BulkDeleteButtonDisable = props => (
    <BulkDeleteButton
        {...props}
        disabled
    />
);

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

export const ToolbarSaveAllDeleteAdmin = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <SaveButton />
        {isAdmin(props.permissions) ? <DeleteButton /> : <DeleteButton disabled />}
    </Toolbar>
));

export const ToolbarSave = props => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
);

export const stylesWide = {
    wide: {
        width: '300px',
    },
};

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 */
export const convertFileToBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file.rawFile);
    });

export const wait = (ms = 0) => new Promise(resolve => setTimeout(resolve, ms));

export function delayAction(callback, delay) {
    setTimeout(() => callback(), delay);
}
export { isAdmin, isGeEmployee, isEmployee, isFranchisee, isPartner, isAgent, isPartnerService };

export const preparePhotoToUpload = async photo => {
    const base64 = await convertFileToBase64(photo);
    return { src: base64, name: photo.name };
};
