import React from 'react';
import { AppBar } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import Logo from './Logo';

const styles = {
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
    appBar: {
        backgroundColor: '#31995a',
    },
    logo: {
        width: '150px',
    },
};

const MyAppBar = withStyles(styles)(({ classes, ...props }) => (
    <AppBar
        {...props}
        className={classes.appBar}
    >
        <Logo className={classes.logo} />
        <span className={classes.spacer} />
    </AppBar>
));

export default MyAppBar;
