import React from 'react';
import {
    BooleanField,
    Datagrid,
    DateField,
    Filter,
    List,
    SelectField,
    SelectInput,
    TextField,
    TextInput,
} from 'react-admin';
import { CustomPagination } from './utils';

const ProfileBonusHistoryFilter = props => (
    <Filter {...props}>
        <SelectInput
            label="Поле"
            source="field"
            choices={[
                { id: 'id', name: 'Id' },
                { id: 'user_id', name: 'Id пользователя' },
                { id: 'order_id', name: 'Id заказа' },
                { id: 'is_income', name: 'Пополнение' },
                { id: 'currency', name: 'Валюта' },
                { id: 'reason', name: 'Причина' },
                { id: 'created', name: 'Время создания' },
            ]}
            alwaysOn
        />
        <TextInput
            label="Поиск"
            source="q"
            alwaysOn
        />
    </Filter>
);

export const ProfileBonusHistoryList = props => (
    <List
        {...props}
        filters={<ProfileBonusHistoryFilter />}
        pagination={<CustomPagination />}
    >
        <Datagrid>
            <TextField
                label="Id"
                source="id"
            />
            <TextField
                label="Id пользователя"
                source="user_id"
            />
            <TextField
                label="Id заказа"
                source="order_id"
            />
            <BooleanField
                label="Пополнение"
                source="is_income"
            />
            <TextField
                label="Валюта"
                source="stripe_id"
            />
            <TextField
                label="Сумма"
                source="amount"
            />
            <SelectField
                label="Причина"
                source="reason"
                choices={[
                    { id: 'payment_success', name: 'Успешный платеж' },
                    { id: 'payment_cancel', name: 'Отмена платежа' },
                    { id: 'order_pay', name: 'В счет заказа' },
                ]}
                allowEmpty
            />
            <DateField
                label="Время создания"
                source="created"
                showTime
                locales="ru-RU"
            />
        </Datagrid>
    </List>
);
