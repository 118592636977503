import React, { Component } from 'react';
import ReactJson from 'react-json-view';
import { Labeled } from 'react-admin';

export class JsonField extends Component {
    render() {
        let { label, record, source, type, jsonString = false, reactJsonOptions = {} } = this.props;
        let src = record[source];
        if (jsonString) {
            src = JSON.parse(src);
        }
        return (
            <Labeled label={label}>
                <ReactJson
                    {...reactJsonOptions}
                    src={src || {}}
                />
            </Labeled>
        );
    }
}
