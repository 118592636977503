import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, crudUpdateMany } from 'react-admin';
import GetAppIcon from '@material-ui/icons/GetApp';
import config from '../config';
import FileSaver from 'file-saver';
import moment from 'moment';

const env = process.env.REACT_APP_CUSTOM_NODE_ENV;

class ExportButton extends Component {
    handleClick = () => {
        const { resource, filterValues, selectedIds } = this.props;
        // const startDate = moment(filterValues.start_date).startOf('day')
        // const endDate = moment(filterValues.end_date).startOf('day')
        const startDate = moment(filterValues.start_date);
        const endDate = moment(filterValues.end_date);
        const body = { ids: selectedIds, startDate, endDate };
        const URL = config[env].url;
        const token = localStorage.getItem('token');
        const permissions = localStorage.getItem('permissions');
        let url;
        if (permissions === 'franchisee') {
            url = `${URL}franchisee/${resource}/export`;
        } else if (permissions === 'partner') {
            url = `${URL}partner/${resource}/export`;
        } else if (permissions === 'agent') {
            url = `${URL}agent/${resource}/export`;
        } else if (permissions === 'partner_service') {
            url = `${URL}partner_service/${resource}/export`;
        } else {
            url = `${URL}admin/${resource}/export`;
        }

        fetch(url, {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
            method: 'POST',
            body: JSON.stringify(body),
        })
            .then(response => {
                if (response.status === 200) {
                    response.arrayBuffer().then(blob => {
                        var q = new Blob([blob], { type: 'application/zip' });
                        FileSaver.saveAs(q, 'reports.zip');
                    });
                } else throw new Error(`Unsupported fetch action type `);
            })
            .catch(e => {
                console.log(e);
            });
    };

    render() {
        return (
            <Button
                label="Экспортировать"
                onClick={this.handleClick}
            >
                <GetAppIcon />
            </Button>
        );
    }
}

export default connect(undefined, { crudUpdateMany })(ExportButton);
