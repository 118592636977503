import React from 'react';
import {
    ArrayInput,
    Create,
    required,
    SimpleForm,
    SimpleFormIterator,
    TextInput,
} from 'react-admin';

const { ipOrHostValidation, urlValidation } = require('../customValidations/index');

export const AddPartnerService = ({ permissions, ...props }) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput
                label="Имя"
                source="name"
                validate={[required()]}
            />

            <TextInput
                label="Описание"
                source="description"
            />

            <TextInput
                label="Владелец станций"
                source="holder_type"
            />

            {/*//TODO*/}
            {/*<ReferenceInput*/}
            {/*    label="Владелец станций"*/}
            {/*    source="holder_type"*/}
            {/*    reference="station_holders"*/}
            {/*    allowEmpty*/}
            {/*>*/}
            {/*    <SelectInput*/}
            {/*        optionText='type'*/}
            {/*    />*/}
            {/*</ReferenceInput>*/}

            <ArrayInput
                label="Разрешенные хосты"
                source="hosts"
            >
                <SimpleFormIterator>
                    <TextInput
                        source="host"
                        validate={[required(), ipOrHostValidation]}
                    />
                </SimpleFormIterator>
            </ArrayInput>

            <TextInput
                label="Callback URL"
                source="callbackURL"
                validate={[required(), urlValidation]}
            />
        </SimpleForm>
    </Create>
);
