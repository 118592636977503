import { createMuiTheme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

const MyTheme = createMuiTheme({
    palette: {
        primary: { 500: '#137C5D' },
        secondary: { A400: '#1BB27A' },
        error: red,
    },
});

export default MyTheme;
