import React from 'react';
import {
    ArrayField,
    ArrayInput,
    Create,
    Datagrid,
    DateField,
    Edit,
    email,
    Filter,
    List,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    SimpleFormIterator,
    TextField,
    TextInput,
} from 'react-admin';
import {
    BulkDeleteButtonAdmin,
    CustomPagination,
    isEmployee,
    isPartnerService,
    ToolbarSaveAllDeleteAdmin,
} from './utils';

const PartnersFilter = props => (
    <Filter {...props}>
        {!isPartnerService(props.permissions) ? (
            <SelectInput
                label="Поле"
                source="field"
                choices={[
                    { id: 'id', name: 'Id партнера' },
                    { id: 'agent_contracts_id', name: 'Id договора с агентом' },
                    { id: 'name', name: 'Имя партнера' },
                    { id: 'details', name: 'Подробности' },
                    { id: 'email', name: 'Электронная почта' },
                    { id: 'status', name: 'Статус партнера' },
                    { id: 'contact', name: 'Контакты' },
                    { id: 'vat_payer', name: 'Плательщик НДС' },
                    { id: 'succ_report_date', name: 'Последний отправленный отчет' },
                    { id: 'created', name: 'Время создания' },
                    { id: 'updated', name: 'Последнее обновление' },
                ]}
                alwaysOn
            />
        ) : (
            <SelectInput
                label="Поле"
                source="field"
                choices={[
                    { id: 'id', name: 'Id ' },
                    { id: 'name', name: 'Имя' },
                    { id: 'description', name: 'Описание' },
                    { id: 'created', name: 'Время создания' },
                    { id: 'updated', name: 'Последнее обновление' },
                ]}
                alwaysOn
            />
        )}
        <TextInput
            label="Поиск"
            source="q"
            alwaysOn
        />
    </Filter>
);

export const PartnersList = ({ permissions, ...props }) => (
    <List
        {...props}
        filters={<PartnersFilter permissions={permissions} />}
        bulkActionButtons={<BulkDeleteButtonAdmin permissions={permissions} />}
        pagination={<CustomPagination />}
    >
        {!isPartnerService(permissions) ? (
            <Datagrid rowClick="edit">
                <TextField
                    label="Id партнера"
                    source="id"
                />
                <TextField
                    label="Id партнера"
                    source="id"
                />
                {isEmployee(permissions) && (
                    <ReferenceField
                        label="Агент"
                        source="agent_id"
                        reference="agents"
                        linkType={false}
                        allowEmpty
                    >
                        <TextField source="name" />
                    </ReferenceField>
                )}
                {isEmployee(permissions) && (
                    <ReferenceField
                        label="Франчайзи"
                        source="franchisee_id"
                        reference="franchisee"
                        linkType={false}
                        allowEmpty
                    >
                        <TextField source="name" />
                    </ReferenceField>
                )}
                <TextField
                    label="Имя партнера"
                    source="name"
                />
                <TextField
                    label="Подробности"
                    source="details"
                />
                <ArrayField
                    label="Электронная почта"
                    source="emails"
                >
                    <Datagrid>
                        <TextField source="email" />
                    </Datagrid>
                </ArrayField>
                <TextField
                    label="Статус партнера"
                    source="status"
                />
                <TextField
                    label="Контакты"
                    source="contact"
                />
                <TextField
                    label="Плательщик НДС"
                    source="vat_payer"
                />
                <DateField
                    label="Последний отправленный отчет"
                    source="succ_report_date"
                    showTime
                    locales="ru-RU"
                />
                <DateField
                    label="Время создания"
                    source="created"
                    showTime
                    locales="ru-RU"
                />
                <DateField
                    label="Последнее обновление"
                    source="updated"
                    showTime
                    locales="ru-RU"
                />
            </Datagrid>
        ) : (
            <Datagrid rowClick="edit">
                <TextField
                    label="Id "
                    source="id"
                />
                <TextField
                    label="Имя "
                    source="name"
                />
                <TextField
                    label="Описание"
                    source="description"
                />
                <DateField
                    label="Время создания"
                    source="created"
                    showTime
                    locales="ru-RU"
                />
                <DateField
                    label="Последнее обновление"
                    source="updated"
                    showTime
                    locales="ru-RU"
                />
            </Datagrid>
        )}
    </List>
);

export const AddPartner = ({ permissions, ...props }) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput
                label="Имя партнера"
                source="name"
            />
            {isEmployee(permissions) && (
                <ReferenceInput
                    perPage={10000}
                    label="Агент"
                    source="agent_id"
                    reference="agents"
                    allowEmpty
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
            )}
            {isEmployee(permissions) && (
                <ReferenceInput
                    perPage={10000}
                    label="Франчайзи"
                    source="franchisee_id"
                    reference="franchisee"
                    allowEmpty
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
            )}
            <TextInput
                label="Подробности"
                source="details"
            />
            <ArrayInput
                label="Электронная почта"
                source="emails"
            >
                <SimpleFormIterator>
                    <TextInput
                        source="email"
                        validate={email()}
                    />
                </SimpleFormIterator>
            </ArrayInput>
            <SelectInput
                label="Статус партнера"
                source="status"
                choices={[
                    { id: 'active', name: 'active' },
                    { id: 'inactive', name: 'inactive' },
                ]}
            />
            <TextInput
                label="Контакты"
                source="contact"
            />
            <SelectInput
                label="Плательщик НДС"
                source="vat_payer"
                choices={[
                    { id: 'yes', name: 'yes' },
                    { id: 'no', name: 'no' },
                ]}
            />
        </SimpleForm>
    </Create>
);

export const EditPartner = ({ permissions, ...props }) => {
    return (
        <Edit {...props}>
            <SimpleForm toolbar={<ToolbarSaveAllDeleteAdmin permissions={permissions} />}>
                <TextInput
                    disabled
                    label="Id партнера"
                    source="id"
                />
                {isEmployee(permissions) && (
                    <ReferenceInput
                        perPage={10000}
                        label="Агент"
                        source="agent_id"
                        reference="agents"
                        allowEmpty
                    >
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                )}
                {isEmployee(permissions) && (
                    <ReferenceInput
                        perPage={10000}
                        label="Франчайзи"
                        source="franchisee_id"
                        reference="franchisee"
                        allowEmpty
                    >
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                )}
                <TextInput
                    label="Имя партнера"
                    source="name"
                />
                <TextInput
                    label="Подробности"
                    source="details"
                />
                <ArrayInput
                    label="Электронная почта"
                    source="emails"
                >
                    <SimpleFormIterator>
                        <TextInput
                            source="email"
                            validate={email()}
                        />
                    </SimpleFormIterator>
                </ArrayInput>
                <SelectInput
                    label="Статус партнера"
                    source="status"
                    choices={[
                        { id: 'active', name: 'active' },
                        { id: 'inactive', name: 'inactive' },
                    ]}
                />
                <TextInput
                    label="Контакты"
                    source="contact"
                />
                <SelectInput
                    label="Плательщик НДС"
                    source="vat_payer"
                    choices={[
                        { id: 'yes', name: 'yes' },
                        { id: 'no', name: 'no' },
                    ]}
                />
                <TextInput
                    disabled
                    label="Время создания"
                    source="created"
                    showTime
                />
                <TextInput
                    disabled
                    label="Последнее обновление"
                    source="updated"
                    showTime
                />
            </SimpleForm>
        </Edit>
    );
};
