import React from 'react';
import { Datagrid, DateField, Filter, List, SelectInput, TextField, TextInput } from 'react-admin';
import { BulkDeleteButtonAdmin, CustomPagination } from '../utils';

const PartnersFilter = props => (
    <Filter {...props}>
        <SelectInput
            label="Поле"
            source="field"
            choices={[
                { id: 'id', name: 'Id ' },
                { id: 'uuid', name: 'UUID ' },
                { id: 'name', name: 'Имя' },
                { id: 'holder_type', name: 'Владелец станций' },
                { id: 'description', name: 'Описание' },
                { id: 'created', name: 'Время создания' },
                { id: 'updated', name: 'Последнее обновление' },
            ]}
            alwaysOn
        />
        <TextInput
            label="Поиск"
            source="q"
            alwaysOn
        />
    </Filter>
);

export const PartnerServicesList = ({ permissions, ...props }) => (
    <List
        {...props}
        filters={<PartnersFilter />}
        bulkActionButtons={<BulkDeleteButtonAdmin permissions={permissions} />}
        pagination={<CustomPagination />}
    >
        <Datagrid rowClick="edit">
            <TextField
                label="Id"
                source="id"
            />
            <TextField
                label="UUID"
                source="uuid"
            />
            <TextField
                label="Имя"
                source="name"
            />
            <TextField
                label="Описание"
                source="description"
            />
            <TextField
                label="Владелец станций"
                source="holder_type"
            />
            <DateField
                label="Время создания"
                source="created"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Последнее обновление"
                source="updated"
                showTime
                locales="ru-RU"
            />
        </Datagrid>
    </List>
);
