import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Confirm, showNotification } from 'react-admin';
import SendIcon from '@material-ui/icons/Send';
import config from '../config';
import moment from 'moment';

const env = process.env.REACT_APP_CUSTOM_NODE_ENV;

class SendButton extends Component {
    state = {
        isOpen: false,
        selectedIds: [],
    };

    handleClick = () => {
        this.setState({ isOpen: true, selectedIds: this.props.selectedIds });
    };

    handleDialogClose = () => {
        this.setState({ isOpen: false });
    };

    // handleConfirm = () => {
    //     const { basePath, crudUpdateMany, resource, selectedIds } = this.props;
    //     crudUpdateMany(resource, selectedIds, { views: 0 }, basePath);
    //     this.setState({ isOpen: true });
    // };

    handleConfirm = () => {
        const { resource, filterValues, selectedIds, showNotification } = this.props;
        // const startDate = moment(filterValues.start_date).startOf('day')
        // const endDate = moment(filterValues.end_date).startOf('day')
        const startDate = moment(filterValues.start_date);
        const endDate = moment(filterValues.end_date);
        const body = { ids: selectedIds, startDate, endDate };
        const URL = config[env].url;
        const token = localStorage.getItem('token');
        const permissions = localStorage.getItem('permissions');
        let url;
        if (permissions === 'franchisee') {
            url = `${URL}franchisee/${resource}/send`;
        } else if (permissions === 'partner') {
            url = `${URL}partner/${resource}/send`;
        } else if (permissions === 'agent') {
            url = `${URL}agent/${resource}/send`;
        } else if (permissions === 'partner_service') {
            url = `${URL}partner_service/${resource}/send`;
        } else {
            url = `${URL}admin/${resource}/send`;
        }

        showNotification('Действие обрабатывается');
        fetch(url, {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
            method: 'POST',
            body: JSON.stringify(body),
        })
            .then(response => {
                response.json().then(data => {
                    if (response.status === 200) {
                        // let who = (resource === 'agent_reports') ? 'агентам' : 'партнерам'
                        // let message = ''
                        // if (data.notSended.length !== 0) {
                        //   message += `Отчет не отправлен ${who}: ${data.notSended.join(', ')}. \n`
                        // }
                        // if (data.sended.length !== 0) {
                        //   message += `Отчет отправлен ${who}: ${data.sended.join(', ')}.`
                        // }
                        showNotification(data.message);
                    } else {
                        showNotification('Ошибка: что-то пошло не так', 'warning');
                    }
                });
            })
            .catch(e => {
                console.error(e);
                showNotification('Ошибка: что-то пошло не так', 'warning');
            });
        this.setState({ isOpen: false });
    };

    render() {
        return (
            <Fragment>
                <Button
                    label="Отправить"
                    onClick={this.handleClick}
                >
                    <SendIcon />
                </Button>
                <Confirm
                    isOpen={this.state.isOpen}
                    title="Отправить отчет"
                    content={`Вы уверены, что хотите отправить отчеты: ${this.state.selectedIds.join(
                        ', ',
                    )}?`}
                    onConfirm={this.handleConfirm}
                    onClose={this.handleDialogClose}
                />
            </Fragment>
        );
    }
}

export default connect(undefined, { showNotification })(SendButton);
