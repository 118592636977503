import React, { useState } from 'react';
import Card from '@material-ui/core/Card';
import Close from '@material-ui/icons/Close';
import { Button, Modal, TextField, Typography, CircularProgress } from '@material-ui/core';
import config from '../../config.json';
import { showNotification } from 'react-admin';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import './styles.css';

const ModalForm = ({ isOpen, setIsOpen, showNotification, push }) => {
    const [value, setValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const handleChange = e => {
        setValue(e.target.value);
    };

    const handleSubmit = async e => {
        e.preventDefault();
        setIsLoading(true);
        const env = process.env.REACT_APP_CUSTOM_NODE_ENV;
        const URL = config[env].url;
        const token = localStorage.getItem('token');
        const headers = new Headers({ Accept: 'application/json' });
        headers.set('Authorization', `Bearer ${token}`);
        const res = await fetch(
            `${URL}admin/external-profiles/find-in-my-mts?phone=${value}`,
            {
                method: 'GET',
                headers,
            }
        );
        const data = await res.json();
        if (res.ok) {
            push(`/profiles/${data.id}`);
        } else {
            setValue('');
            setIsOpen(false);
            showNotification(data.message, 'warning');
        }
        setIsLoading(false);
    };

    const handleClose = () => {
        setValue('');
        setIsOpen(false);
    };

    return (
        <Modal
            open={isOpen}
            onBackdropClick={handleClose}
        >
            <div className={'modal__wrapper'}>
                <Card className={'modal__card'}>
                    <Button
                        onClick={handleClose}
                        className={'modal__close-button'}
                    >
                        <Close />
                    </Button>
                    <form
                        className={'modal__form'}
                        onSubmit={handleSubmit}
                    >
                        <Typography
                            variant={'title'}
                            align={'center'}
                        >
                            Найти в My Mts
                        </Typography>
                        <TextField
                            autoFocus
                            label={'Номер телефона'}
                            value={value}
                            onChange={handleChange}
                        />
                        <Button
                            type={'submit'}
                            color={'primary'}
                            variant={'contained'}
                        >
                            Найти в My Mts
                        </Button>
                        {isLoading && (
                            <div className={'modal__loading'}>
                                <CircularProgress />
                            </div>
                        )}
                    </form>
                </Card>
            </div>
        </Modal>
    );
};

export default connect(null, {
    showNotification,
    push,
})(ModalForm);
