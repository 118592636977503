import React from 'react';
import PropTypes from 'prop-types';

const TimeField = ({ source, record = {} }) => {
    const value = record[source];
    if (value) {
        const date = value instanceof Date ? value : new Date(value);
        const hours = date.getHours();
        const minutes = '0' + date.getMinutes();
        const formattedTime = hours + ':' + minutes.substr(-2);
        return <span>{formattedTime}</span>;
    } else {
        return null;
    }
};

TimeField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default TimeField;
