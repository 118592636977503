const INIT_STATE = {
    snoList: [],
    active: '',
    slotsList: {},
    logsList: [],
    datetime: '',
    socket: null,
    isLoading: false,
};

export default (state = INIT_STATE, action) => {
    console.log(action.type);
    switch (action.type) {
        case 'LOADING_START':
            return {
                ...state,
                isLoading: true,
            };
        case 'LOADING_END':
            return {
                ...state,
                isLoading: false,
            };
        case 'SNO_LIST_LOAD':
            return {
                ...state,
                snoList: action.payload,
            };
        case 'SLOTS_LIST_LOAD':
            return {
                ...state,
                slotsList: action.payload,
                isLoading: false,
            };
        case 'SLOTS_LIST_ERROR':
            return {
                ...state,
                active: null,
                isLoading: false,
            };
        case 'LOGS_LIST_LOAD':
            return {
                ...state,
                logsList: action.payload,
                isLoading: false,
            };
        case 'EXTRACT':
            return {
                ...state,
                extract: action.payload,
                isLoading: false,
            };
        case 'CHOICE':
            return {
                ...state,
                active: action.payload.sno,
                datetime: action.payload.datetime,
                logsList: [],
                isLoading: false,
            };
        case 'ADMIN_STATUS_UPDATED': {
            return {
                ...state,
                isLoading: false,
                slotsList: {
                    ...state.slotsList,
                    [[parseInt(action.payload.pksnopos.split(':')[1])].admin_status]:
                        action.payload.admin_status,
                },
            };
        }
        case 'CONFLICT_STATUS_UPDATED': {
            return {
                ...state,
                slotsList: {
                    ...state.slotsList,
                    [[parseInt(action.payload.pksnopos.split(':')[1])].conflict_status]:
                        action.payload.conflict_status,
                },
            };
        }
        case 'WS_CONNECTED':
            state.socket && state.socket.disconnect();
            return {
                ...state,
                socket: action.payload,
            };
        case 'WS_MESSAGE':
            return {
                ...state,
                logsList: [action.payload, ...state.logsList],
            };
        case 'WS_CLOSED':
            return {
                ...state,
                socket: null,
            };
        case 'WS_CLOSE':
            return {
                ...state,
                socket: null,
            };
        default:
            return state;
    }
};
