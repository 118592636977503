import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { Button } from '@material-ui/core';
import { SelectInput } from 'react-admin';

class ChangeStatus extends Component {
    componentDidMount() {
        this.props.initialize({ sno: this.props.active });
    }

    componentDidUpdate(prevProps) {
        if (this.props.active !== prevProps.active) {
            this.props.initialize({ sno: this.props.active });
        }
    }

    render() {
        const { handleSubmit } = this.props;
        return (
            <form
                onSubmit={handleSubmit}
                style={{ display: 'flex', alignItems: 'end' }}
            >
                <SelectInput
                    style={{ display: 'inline-flex', width: '200px', marginTop: 0 }}
                    label="Изменить все статусы"
                    source="conflict_status"
                    choices={[
                        { id: 'not_confirmed', name: 'not_confirmed' },
                        { id: 'operational', name: 'operational' },
                    ]}
                />
                <Button
                    style={{ marginBottom: '8px', marginLeft: '8px' }}
                    type="submit"
                    color="primary"
                >
                    Изменить
                </Button>
            </form>
        );
    }
}

export default reduxForm({
    form: 'simple',
})(ChangeStatus);
