import moment from 'moment';

const INIT_STATE = {
    data: [],
    params: {
        startDate: moment().startOf('month').toDate(),
        endDate: moment().startOf('day').toDate(),
    },
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case 'DATA_LOAD':
            return {
                ...state,
                data: action.payload,
            };
        case 'CHANGE_PARAMS':
            return {
                ...state,
                params: action.payload,
            };
        default:
            return state;
    }
};
