import React, { useEffect, useState } from 'react';
import { Typography, withStyles } from '@material-ui/core/';
import styles from './styles';
import { AllDayCheckbox, DontWorkCheckbox, TimeInputAdapter } from './components';
import { timeStringToDate } from '../../utils';
import { TimeInput } from 'react-admin-date-inputs';

const EditableScheduleView = withStyles(styles)(({ classes, schedule, timezone, ...rest }) => {
    const daysOfWeek = [
        'Понедельник',
        'Вторник',
        'Среда',
        'Четверг',
        'Пятница',
        'Суббота',
        'Воскресенье',
    ];

    const [scheduleState, setScheduleState] = useState({
        1: { isAllDay: false, isWorking: true },
        2: { isAllDay: false, isWorking: true },
        3: { isAllDay: false, isWorking: true },
        4: { isAllDay: false, isWorking: true },
        5: { isAllDay: false, isWorking: true },
        6: { isAllDay: false, isWorking: true },
        7: { isAllDay: false, isWorking: true },
    });

    useEffect(() => {
        if (schedule) {
            const correctScheduleState = Object.entries(schedule).reduce((acc, [key, value]) => {
                if (value.length === 0) {
                    return { ...acc, [+key]: { isAllDay: false, isWorking: false } };
                }
                if (value[0][0].startsWith('00:00') && value[0][1].startsWith('00:00')) {
                    return { ...acc, [+key]: { isAllDay: true, isWorking: true } };
                }

                return { ...acc, [+key]: { isAllDay: false, isWorking: true } };
            }, {});

            setScheduleState(correctScheduleState);
        }
    }, [schedule]);

    return (
        <div className={classes.box}>
            {Object.entries(schedule).map(([dayNumber, intervals], index) => {
                return (
                    <div
                        key={dayNumber}
                        style={{ width: '100%' }}
                    >
                        <Typography variant={'title'}>{daysOfWeek[index]}</Typography>
                        <AllDayCheckbox
                            disabled={true}
                            label={'Станция работает весь день'}
                            scheduleState={scheduleState}
                            setScheduleState={setScheduleState}
                            index={index + 1}
                        />
                        <DontWorkCheckbox
                            disabled={true}
                            label={'Станция не работает'}
                            scheduleState={scheduleState}
                            setScheduleState={setScheduleState}
                            index={index + 1}
                        />
                        {intervals.map(interval => {
                            return (
                                <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                                    <TimeInput
                                        label={'Начало интервала'}
                                        isRequired={false}
                                        input={{
                                            value: interval[0]
                                                ? timeStringToDate(interval[0], timezone)
                                                : '',
                                        }}
                                        options={{ ampm: false, disabled: true }}
                                    />

                                    <TimeInput
                                        label={'Конец интервала'}
                                        isRequired={false}
                                        input={{
                                            value: interval[1]
                                                ? timeStringToDate(interval[1], timezone)
                                                : '',
                                        }}
                                        options={{ ampm: false, disabled: true }}
                                    />
                                </div>
                            );
                        })}
                    </div>
                );
            })}
        </div>
    );
});

const ShowScheduleField = props => {
    return (
        <EditableScheduleView
            schedule={props.schedule}
            timezone={props.timezone}
        />
    );
};

export default ShowScheduleField;
