import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slot from './Slot';
import Open from './Open';
import ChangeStatus from './ChangeStatus';
import { stationModels } from '../../constants/stationModels';
import Logs from './Logs';
import DebounceButton from './DebounceButton';

class Station extends Component {
    state = {
        stationModels,
    };

    componentDidMount() {
        this.props.loadSnoList();
    }

    render() {
        const {
            logsList,
            snoList,
            slotsList,
            active,
            extract,
            restart,
            updateAdminStatus,
            updateConflictStatus,
            open,
            changeAllStatuses,
        } = this.props;
        const station = snoList.find(x => x.sno === active);

        let slotsWrapper = '';
        if (station) {
            switch (station.capacity) {
                case 4: {
                    switch (station.model) {
                        case stationModels.relink_station: {
                            slotsWrapper = [0, 1].map(i => (
                                <div
                                    key={2 - i}
                                    id={'row_' + (2 - i).toString()}
                                    className="station-row"
                                >
                                    {[0, 1].map(j => (
                                        <Slot
                                            key={2 * (j + 1) - i}
                                            num={(2 * (j + 1) - i).toString()}
                                            params={slotsList[2 * (j + 1) - i]}
                                            extract={extract}
                                            sno={active}
                                            updateAdminStatus={updateAdminStatus}
                                            updateConflictStatus={updateConflictStatus}
                                        />
                                    ))}
                                </div>
                            ));
                            break;
                        }
                        case stationModels.dudu_station: {
                            slotsWrapper = [...Array(2).keys()].map(i => (
                                <div
                                    key={i + 1}
                                    id={'row_' + (i + 1).toString()}
                                    className="station-row"
                                >
                                    {[...Array(2).keys()].reverse().map(j => (
                                        <Slot
                                            key={2 * j + (i + 1)}
                                            num={(2 * j + (i + 1)).toString()}
                                            params={slotsList[2 * j + (i + 1)]}
                                            extract={extract}
                                            sno={active}
                                            updateAdminStatus={updateAdminStatus}
                                            updateConflictStatus={updateConflictStatus}
                                        />
                                    ))}
                                </div>
                            ));
                            break;
                        }
                    }
                    break;
                }
                case 6: {
                    switch (station.model) {
                        case stationModels.relink_station: {
                            slotsWrapper = [0, 1, 2].map(i => (
                                <div
                                    key={3 - i}
                                    id={'row_' + (3 - i).toString()}
                                    className="station-row"
                                >
                                    {[0, 1].map(j => (
                                        <Slot
                                            key={3 * (j + 1) - i}
                                            num={(3 * (j + 1) - i).toString()}
                                            params={slotsList[3 * (j + 1) - i]}
                                            extract={extract}
                                            sno={active}
                                            updateAdminStatus={updateAdminStatus}
                                            updateConflictStatus={updateConflictStatus}
                                        />
                                    ))}
                                </div>
                            ));
                            break;
                        }
                        case stationModels.dudu_station: {
                            slotsWrapper = [...Array(3).keys()].map(i => (
                                <div
                                    key={i + 1}
                                    id={'row_' + (i + 1).toString()}
                                    className="station-row"
                                >
                                    {[...Array(2).keys()].reverse().map(j => (
                                        <Slot
                                            key={3 * j + (i + 1)}
                                            num={(3 * j + (i + 1)).toString()}
                                            params={slotsList[3 * j + (i + 1)]}
                                            extract={extract}
                                            sno={active}
                                            updateAdminStatus={updateAdminStatus}
                                            updateConflictStatus={updateConflictStatus}
                                        />
                                    ))}
                                </div>
                            ));
                            break;
                        }
                        default: {
                            slotsWrapper = [0, 1, 2].map(i => (
                                <div
                                    key={i + 1}
                                    id={'row_' + (i + 1).toString()}
                                    className="station-row"
                                >
                                    {[0, 1].map(j => (
                                        <Slot
                                            key={2 * (i + 1) - j}
                                            num={(2 * (i + 1) - j).toString()}
                                            params={slotsList[2 * (i + 1) - j]}
                                            extract={extract}
                                            sno={active}
                                            updateAdminStatus={updateAdminStatus}
                                            updateConflictStatus={updateConflictStatus}
                                        />
                                    ))}
                                </div>
                            ));
                            break;
                        }
                    }
                    break;
                }
                case 24: {
                    switch (station.model) {
                        case stationModels.relink_station: {
                            slotsWrapper = [...Array(12).keys()]
                                .reverse()
                                .map(row => +row + 1)
                                .map(row => (
                                    <div
                                        key={row}
                                        id={'row_' + row.toString()}
                                        className="station-row"
                                    >
                                        {[1, 2].map(column => (
                                            <Slot
                                                key={row + 12 * (column - 1)}
                                                num={(row + 12 * (column - 1)).toString()}
                                                params={slotsList[row + 12 * (column - 1)]}
                                                extract={extract}
                                                sno={active}
                                                updateAdminStatus={updateAdminStatus}
                                                updateConflictStatus={updateConflictStatus}
                                            />
                                        ))}
                                    </div>
                                ));
                            break;
                        }
                        case stationModels.dudu_station: {
                            slotsWrapper = [...Array(12).keys()].map(i => (
                                <div
                                    key={i + 1}
                                    id={'row_' + (i + 1).toString()}
                                    className="station-row"
                                >
                                    {[...Array(2).keys()].reverse().map(j => (
                                        <Slot
                                            key={12 * j + (i + 1)}
                                            num={(12 * j + (i + 1)).toString()}
                                            params={slotsList[12 * j + (i + 1)]}
                                            extract={extract}
                                            sno={active}
                                            updateAdminStatus={updateAdminStatus}
                                            updateConflictStatus={updateConflictStatus}
                                        />
                                    ))}
                                </div>
                            ));
                            break;
                        }
                        default: {
                            slotsWrapper = [...Array(8).keys()].map(i => (
                                <div
                                    key={i + 1}
                                    id={'row_' + (i + 1).toString()}
                                    className="station-row"
                                >
                                    {[0, 1, 2].map(j => (
                                        <Slot
                                            key={3 * (i + 1) - j}
                                            num={(3 * (i + 1) - j).toString()}
                                            params={slotsList[3 * (i + 1) - j]}
                                            extract={extract}
                                            sno={active}
                                            updateAdminStatus={updateAdminStatus}
                                            updateConflictStatus={updateConflictStatus}
                                        />
                                    ))}
                                </div>
                            ));
                            break;
                        }
                    }
                    break;
                }
                case 12: {
                    switch (station.model) {
                        case stationModels.relink_station: {
                            slotsWrapper = [...Array(6).keys()]
                                .reverse()
                                .map(row => +row + 1)
                                .map(row => (
                                    <div
                                        key={row}
                                        id={'row_' + row.toString()}
                                        className="station-row"
                                    >
                                        {[1, 2].map(column => (
                                            <Slot
                                                key={row + 6 * (column - 1)}
                                                num={(row + 6 * (column - 1)).toString()}
                                                params={slotsList[row + 6 * (column - 1)]}
                                                extract={extract}
                                                sno={active}
                                                updateAdminStatus={updateAdminStatus}
                                                updateConflictStatus={updateConflictStatus}
                                            />
                                        ))}
                                    </div>
                                ));
                            break;
                        }
                        case stationModels.dudu_station: {
                            slotsWrapper = [...Array(6).keys()].map(i => (
                                <div
                                    key={i + 1}
                                    id={'row_' + (i + 1).toString()}
                                    className="station-row"
                                >
                                    {[...Array(2).keys()].reverse().map(j => (
                                        <Slot
                                            key={6 * j + (i + 1)}
                                            num={(6 * j + (i + 1)).toString()}
                                            params={slotsList[6 * j + (i + 1)]}
                                            extract={extract}
                                            sno={active}
                                            updateAdminStatus={updateAdminStatus}
                                            updateConflictStatus={updateConflictStatus}
                                        />
                                    ))}
                                </div>
                            ));
                            break;
                        }
                        default: {
                            slotsWrapper = [0, 1, 2, 3].map(i => (
                                <div
                                    key={i + 1}
                                    id={'row_' + (i + 1).toString()}
                                    className="station-row"
                                >
                                    {[0, 1, 2].map(j => (
                                        <Slot
                                            key={3 * (i + 1) - j}
                                            num={(3 * (i + 1) - j).toString()}
                                            params={slotsList[3 * (i + 1) - j]}
                                            extract={extract}
                                            sno={active}
                                            updateAdminStatus={updateAdminStatus}
                                            updateConflictStatus={updateConflictStatus}
                                        />
                                    ))}
                                </div>
                            ));
                        }
                    }
                    break;
                }
                case 18: {
                    slotsWrapper = [...Array(9).keys()]
                        .reverse()
                        .map(row => +row + 1)
                        .map(row => (
                            <div
                                key={row}
                                id={'row_' + row.toString()}
                                className="station-row"
                            >
                                {[1, 2].map(column => (
                                    <Slot
                                        key={row + 9 * (column - 1)}
                                        num={(row + 9 * (column - 1)).toString()}
                                        params={slotsList[row + 9 * (column - 1)]}
                                        extract={extract}
                                        sno={active}
                                        updateAdminStatus={updateAdminStatus}
                                        updateConflictStatus={updateConflictStatus}
                                    />
                                ))}
                            </div>
                        ));
                    break;
                }
                case 8: {
                    slotsWrapper = [1, 2, 3, 4].map(row => (
                        <div
                            key={row}
                            id={'row_' + row.toString()}
                            className="station-row"
                        >
                            {[1, 2].map(column => (
                                <Slot
                                    key={column * 4 + 1 - row}
                                    num={(column * 4 + 1 - row).toString()}
                                    params={slotsList[column * 4 + 1 - row]}
                                    extract={extract}
                                    sno={active}
                                    updateAdminStatus={updateAdminStatus}
                                    updateConflictStatus={updateConflictStatus}
                                />
                            ))}
                        </div>
                    ));
                    break;
                }
                case 48: {
                    slotsWrapper = [...Array(12).keys()]
                        .reverse()
                        .map(row => +row + 1)
                        .map(row => (
                            <div
                                key={row}
                                id={'row_' + row.toString()}
                                className="station-row"
                            >
                                {[1, 2, 3, 4].map(column => (
                                    <Slot
                                        key={row + 12 * (column - 1)}
                                        num={(row + 12 * (column - 1)).toString()}
                                        params={slotsList[row + 12 * (column - 1)]}
                                        extract={extract}
                                        sno={active}
                                        updateAdminStatus={updateAdminStatus}
                                        updateConflictStatus={updateConflictStatus}
                                    />
                                ))}
                            </div>
                        ));
                    break;
                }
            }
            if (!slotsWrapper) {
                slotsWrapper = [0, 1, 2, 3].map(i => (
                    <div
                        key={i + 1}
                        id={'row_' + (i + 1).toString()}
                        className="station-row"
                    >
                        {[0, 1, 2].map(j => (
                            <Slot
                                key={3 * (i + 1) - j}
                                num={(3 * (i + 1) - j).toString()}
                                params={slotsList[3 * (i + 1) - j]}
                                extract={extract}
                                sno={active}
                                updateAdminStatus={updateAdminStatus}
                                updateConflictStatus={updateConflictStatus}
                            />
                        ))}
                    </div>
                ));
            }
        }
        return (
            <div className="station-wrapper">
                <div id="station">{slotsWrapper}</div>
                <div className="station-controls">
                    <div className="station-control">
                        <div className="station-control-label">
                            Валидация всех зарядников станции
                        </div>
                        <div id="change_status">
                            <ChangeStatus
                                onSubmit={changeAllStatuses}
                                active={active}
                            />
                        </div>
                    </div>
                    <div className="station-control">
                        <div className="station-control-label">Извлечение по слоту</div>
                        <div id="open">
                            <Open
                                onSubmit={open}
                                active={active}
                                capacity={station ? station.capacity : 0}
                            />
                        </div>
                    </div>
                    <div
                        className="station-control"
                        style={{
                            display:
                                station && station.model === stationModels.relink_station
                                    ? 'flex'
                                    : 'none',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '8px',
                        }}
                    >
                        <div>Перезагрузка</div>
                        <DebounceButton onRefresh={() => restart(station.sno)}></DebounceButton>
                        {/* <div
                            style={{
                                height: '56px',
                                display: 'grid',
                                alignItems: 'center',
                                justifyItems: 'center',
                                cursor: 'pointer',
                            }}
                            onClick={() => restart(station.sno)}
                        >
                            <img
                                alt=""
                                height={32}
                                style={{
                                    display: 'block',
                                    teansition: 'all 0.3s',
                                }}
                                src="/refresh.png"
                                title="перезагрузить"
                            ></img>
                        </div> */}
                    </div>
                    <div></div>
                </div>
                <Logs logsList={logsList} />
            </div>
        );
    }
}

Station.propTypes = {
    logsList: PropTypes.array,
    slotsList: PropTypes.object,
    snoList: PropTypes.array,
    active: PropTypes.string,
    extract: PropTypes.func,
    loadSnoList: PropTypes.func,
    open: PropTypes.func,
    updateAdminStatus: PropTypes.func,
    updateConflictStatus: PropTypes.func,
    changeAllStatuses: PropTypes.func,
};

export default Station;
