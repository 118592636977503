import React from 'react';
import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    DateField,
    Edit,
    Filter,
    List,
    NumberInput,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput,
} from 'react-admin';
import { BulkDeleteButtonAdmin, CustomPagination, ToolbarSaveAllDeleteAdmin } from './utils';

const DeviceFilter = props => (
    <Filter {...props}>
        <SelectInput
            label="Поле"
            source="field"
            choices={[
                { id: 'dno', name: 'Dno' },
                { id: 'dtype', name: 'Тип' },
                { id: 'bc', name: 'Заряд' },
                { id: 'status', name: 'Статус' },
                { id: 'admin_status', name: 'Адм. статус' },
                { id: 'conflict_status', name: 'Конфликт статус' },
                { id: 'pksnopos', name: 'Позиция' },
                { id: 'session_id', name: 'Сессия' },
                { id: 'admin_ejection', name: 'Адм. извлечение' },
                { id: 'is_our', name: 'Наше устройство' },
                { id: 'comment', name: 'Комментарий' },
                { id: 'last_message', name: 'Последнее сообщение' },
                { id: 'created', name: 'Время создания' },
                { id: 'updated', name: 'Последнее обновление' },
            ]}
            alwaysOn
        />
        <TextInput
            label="Поиск"
            source="q"
            alwaysOn
        />
    </Filter>
);

const PercentField = ({ record = {}, source }) =>
    record[source] || record[source] === 0 ? <span>{record[source]}%</span> : <span />;

export const DevicesList = ({ permissions, ...props }) => (
    <List
        {...props}
        filters={<DeviceFilter />}
        bulkActionButtons={<BulkDeleteButtonAdmin permissions={permissions} />}
        pagination={<CustomPagination />}
    >
        <Datagrid rowClick="edit">
            <TextField source="dno" />
            <TextField
                label="Тип"
                source="dtype"
            />
            <PercentField
                label="Заряд"
                source="bc"
            />
            <TextField
                label="Статус"
                source="status"
            />
            <TextField
                label="Адм. статус"
                source="admin_status"
            />
            <TextField
                label="Конфликт статус"
                source="conflict_status"
            />
            <TextField
                label="Позиция"
                source="pksnopos"
            />
            <TextField
                label="Пред. позиция"
                source="prev_pksnopos"
            />
            <TextField
                label="Сессия"
                source="session_id"
            />
            <TextField
                label="Адм. извлечение"
                source="admin_ejection"
            />
            <BooleanField
                label="Наше устройство"
                source="is_our"
            />
            <TextField
                label="Комментарий"
                source="comment"
            />
            <TextField
                label="Overwritten"
                source="overwritten"
            />
            <DateField
                label="Последнее сообщение"
                source="last_message"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Время создания"
                source="created"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Последнее обновление"
                source="updated"
                showTime
                locales="ru-RU"
            />
        </Datagrid>
    </List>
);

export const AddDevice = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="dno" />
            <NumberInput
                label="Тип"
                source="dtype"
            />
            <NumberInput
                label="Заряд"
                source="bc"
            />
            <TextInput
                label="Статус"
                source="status"
            />
            <SelectInput
                label="Адм. статус"
                source="admin_status"
                choices={[
                    { id: 'active', name: 'active' },
                    { id: 'blocked', name: 'blocked' },
                ]}
            />
            <SelectInput
                label="Конфликт статус"
                source="conflict_status"
                choices={[
                    { id: 'not_confirmed', name: 'not_confirmed' },
                    { id: 'operational', name: 'operational' },
                ]}
            />
            <TextInput
                label="Позиция"
                source="pksnopos"
            />
            <NumberInput
                label="Сессия"
                source="session_id"
            />
            <TextInput
                label="Адм. извлечение"
                source="admin_ejection"
            />
            <BooleanInput
                label="Наше устройство"
                source="is_our"
            />
            <TextInput
                label="Комментарий"
                source="comment"
            />
            <TextInput
                label="Overwritten"
                source="overwritten"
            />
        </SimpleForm>
    </Create>
);

export const EditDevice = ({ permissions, ...props }) => (
    <Edit {...props}>
        <SimpleForm toolbar={<ToolbarSaveAllDeleteAdmin permissions={permissions} />}>
            <TextInput
                disabled
                label="Id"
                source="id"
            />
            <TextInput
                disabled
                source="dno"
            />
            <NumberInput
                label="Тип"
                source="dtype"
            />
            <NumberInput
                label="Заряд"
                source="bc"
            />
            <TextInput
                label="Статус"
                source="status"
            />
            <SelectInput
                label="Адм. статус"
                source="admin_status"
                choices={[
                    { id: 'active', name: 'active' },
                    { id: 'blocked', name: 'blocked' },
                ]}
            />
            <SelectInput
                label="Конфликт статус"
                source="conflict_status"
                choices={[
                    { id: 'not_confirmed', name: 'not_confirmed' },
                    { id: 'operational', name: 'operational' },
                ]}
            />
            <TextInput
                label="Позиция"
                source="pksnopos"
            />
            <TextInput
                disabled
                label="Предыдущая позиция"
                source="prev_pksnopos"
            />
            <NumberInput
                label="Сессия"
                source="session_id"
            />
            <TextInput
                label="Адм. извлечение"
                source="admin_ejection"
            />
            <BooleanInput
                options={{ disabled: true }}
                label="Наше устройство"
                source="is_our"
            />
            <TextInput
                label="Комментарий"
                source="comment"
            />
            <TextInput
                label="Overwritten"
                source="overwritten"
            />
            <TextInput
                disabled
                label="Последнее сообщение"
                source="last_message"
            />
            <TextInput
                disabled
                label="Время создания"
                source="created"
            />
            <TextInput
                disabled
                label="Последнее обновление"
                source="updated"
            />
        </SimpleForm>
    </Edit>
);
