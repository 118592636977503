import React, { Component } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { stationShortNumberDivider } from '../../constants/stations';

const styles = {
    menu: base => ({
        ...base,
        width: 'max-content',
        minWidth: '100%',
    }),
};

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};
const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    marginLeft: '10px',
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
};

const labelStyle = {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    fontSize: '1rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: 1,
};
const formatGroupLabel = (data, isShortNumber) => (
    <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>
            {isShortNumber ? `${data.optionsWithShortNumber.length}/` : ''}
            {data.optionsLength}
        </span>
    </div>
);
class StationSwitcher extends Component {
    render() {
        const { snoList, choice, active } = this.props;
        let shortNumberBySno = new Map();
        let snoByShortNumber = new Map();
        let groups = [];

        if (snoList.length > 0) {
            groups = Object.values(
                snoList.reduce((p, c) => {
                    const model = c.model ? c.model : 'OLD';
                    if (!p[model]) {
                        p[model] = {
                            label: model,
                            options: [],
                            optionsLength: 0,
                            optionsWithShortNumber: [],
                        };
                    }
                    p[model].optionsLength++;
                    const short_number =
                        c.holder_prefix && c.holder_short_number
                            ? `${c.holder_prefix}${stationShortNumberDivider}${c.holder_short_number}`
                            : c.holder_short_number;
                    if (c.holder_short_number) {
                        snoByShortNumber.set(short_number, c.sno);
                        p[model].optionsWithShortNumber.push(c);
                    }
                    c.short_number = short_number;
                    p[model].options.push(c);
                    shortNumberBySno.set(c.sno, short_number);
                    return p;
                }, {}),
            ).filter(v => v.optionsLength);
        }
        return (
            <div className="station_switcher">
                <div>
                    <label
                        id="station_sno-aria-label"
                        htmlFor="station_sno"
                        style={labelStyle}
                    >
                        SNO станции
                    </label>
                    <Select
                        styles={{
                            ...styles,
                            control: css => ({
                                ...css,
                                width: '250px',
                            }),
                            menu: css => ({
                                ...css,
                                width: '250px',
                            }),
                            container: css => ({
                                ...css,
                                marginTop: '5px',
                                fontSize: '0.875rem',
                                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                            }),
                        }}
                        aria-labelledby="station_sno-aria-label"
                        inputId="station_sno"
                        options={groups}
                        value={active ? { sno: active } : null}
                        onChange={value => choice(value.sno)}
                        formatGroupLabel={formatGroupLabel}
                        getOptionLabel={option => option.sno}
                        getOptionValue={option => option.sno}
                        menuShouldScrollIntoView={true}
                        placeholder="..."
                    />
                </div>
                <div>
                    <label
                        id="station_short_number-aria-label"
                        htmlFor="station_short_number"
                        style={labelStyle}
                    >
                        Код станции
                    </label>
                    <Select
                        styles={{
                            ...styles,
                            control: css => ({
                                ...css,
                                width: '250px',
                            }),
                            menu: css => ({
                                ...css,
                                width: '250px',
                            }),
                            container: css => ({
                                ...css,
                                marginTop: '5px',
                                fontSize: '0.875rem',
                                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                            }),
                        }}
                        aria-labelledby="station_short_number-aria-label"
                        inputId="station_short_number"
                        options={groups
                            .filter(g => g.optionsWithShortNumber.length)
                            .map(g => ({
                                ...g,
                                options: g.optionsWithShortNumber,
                            }))}
                        value={
                            active
                                ? {
                                      short_number: shortNumberBySno.get(active)
                                          ? shortNumberBySno.get(active)
                                          : 'Нет',
                                  }
                                : null
                        }
                        onChange={value => choice(snoByShortNumber.get(value.short_number))}
                        formatGroupLabel={data => formatGroupLabel(data, true)}
                        getOptionLabel={option => option.short_number}
                        getOptionValue={option => option.short_number}
                        menuShouldScrollIntoView={true}
                        placeholder="..."
                    />
                </div>
            </div>
        );
    }
}

StationSwitcher.propTypes = {
    snoList: PropTypes.array,
    choice: PropTypes.func,
    active: PropTypes.string,
};

export default StationSwitcher;
