import React, { useState } from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';

const DontWorkCheckbox = ({
    scheduleState,
    setScheduleState,
    index,
    input,
    label,
    disabled = false,
}) => {
    const [prevValue, setPrevValue] = useState(null);
    const handler = e => {
        const { checked } = e.target;
        setScheduleState(prev => ({ ...prev, [index]: { ...prev[index], isWorking: !checked } }));
        if (checked) {
            input.onChange([]);
            setPrevValue(input.value);
        } else {
            if (prevValue) {
                input.onChange(prevValue);
                setPrevValue(null);
            }
        }
    };

    const { isWorking, isAllDay } = scheduleState[index];
    return (
        <FormControlLabel
            label={label}
            control={
                <Checkbox
                    disabled={isAllDay || disabled}
                    checked={!isWorking}
                    onChange={handler}
                />
            }
        />
    );
};

export default DontWorkCheckbox;
