import React from 'react';
import {
    List,
    Datagrid,
    SelectInput,
    TextField,
    DateField,
    SimpleForm,
    Create,
    Edit,
    TextInput,
    Filter,
    required,
} from 'react-admin';
import { CustomPagination, BulkDeleteButtonAdmin, ToolbarSaveAllDeleteAdmin } from './utils';

const PartnersFilter = props => (
    <Filter {...props}>
        <SelectInput
            label="Поле"
            source="field"
            choices={[
                { id: 'id', name: 'Id партнера' },
                { id: 'text_id', name: 'Text id' },
                { id: 'text', name: 'Текст' },
                { id: 'text_type', name: 'Тип' },
                { id: 'locale', name: 'Локаль' },
                { id: 'created', name: 'Время создания' },
                { id: 'updated', name: 'Последнее обновление' },
            ]}
            alwaysOn
        />
        <TextInput
            label="Поиск"
            source="q"
            alwaysOn
        />
    </Filter>
);

export const TextsList = ({ permissions, ...props }) => (
    <List
        {...props}
        filters={<PartnersFilter />}
        bulkActionButtons={<BulkDeleteButtonAdmin permissions={permissions} />}
        pagination={<CustomPagination />}
    >
        <Datagrid rowClick="edit">
            <TextField
                label="Id"
                source="id"
            />
            <TextField
                label="Text id"
                source="text_id"
            />
            <TextField
                label="Текст"
                source="text"
            />
            <TextField
                label="Тип"
                source="text_type"
            />
            <TextField
                label="Локаль"
                source="locale"
            />
            <DateField
                label="Время создания"
                source="created"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Последнее обновление"
                source="updated"
                showTime
                locales="ru-RU"
            />
        </Datagrid>
    </List>
);

export const AddText = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput
                label="Text id"
                source="text_id"
                validate={required()}
            />
            <TextInput
                label="Текст"
                source="text"
            />
            <TextInput
                label="Тип"
                source="text_type"
            />
            <TextInput
                label="Локаль"
                source="locale"
                validate={required()}
            />
        </SimpleForm>
    </Create>
);

export const EditText = ({ permissions, ...props }) => (
    <Edit {...props}>
        <SimpleForm toolbar={<ToolbarSaveAllDeleteAdmin permissions={permissions} />}>
            <TextInput
                disabled
                label="Id"
                source="id"
            />
            <TextInput
                disabled
                label="Text id"
                source="text_id"
            />
            <TextInput
                label="Текст"
                source="text"
            />
            <TextInput
                label="Тип"
                source="text_type"
            />
            <TextInput
                label="Локаль"
                source="locale"
                validate={required()}
            />
            <TextInput
                disabled
                label="Время создания"
                source="created"
            />
            <TextInput
                disabled
                label="Последнее обновление"
                source="updated"
            />
        </SimpleForm>
    </Edit>
);
