import React, { Fragment } from 'react';
import { List, Datagrid, TextField, Filter } from 'react-admin';
import { DateInput } from 'react-admin-date-inputs';
import moment from 'moment';
import ExportButton from './reports/ExportButton';
import SendButton from './reports/SendButton';
import { isEmployee } from './utils';

const FranchiseeReportsFilter = props => {
    return (
        <Filter {...props}>
            <DateInput
                label="C"
                source="start_date"
                options={{ format: 'dd.MM.YYYY' }}
                isRequired={false}
                alwaysOn
            />
            <DateInput
                label="До"
                source="end_date"
                options={{ format: 'dd.MM.YYYY' }}
                isRequired={false}
                alwaysOn
            />
        </Filter>
    );
};

const BulkActionButtons = props => (
    <Fragment>
        <ExportButton
            label="Экспортировать"
            {...props}
        />
        {/* {isEmployee(props.permissions) && <SendButton label="Отправить" {...props}/>} */}
    </Fragment>
);

export const FranchiseeReportsList = ({ permissions, ...props }) => (
    <List
        {...props}
        filters={<FranchiseeReportsFilter />}
        // pagination={false}
        exporter={false}
        filterDefaultValues={{
            start_date: moment().startOf('month').toDate(),
            end_date: moment().startOf('day').toDate(),
        }}
        bulkActionButtons={<BulkActionButtons permissions={permissions} />}
    >
        <Datagrid rowClick="edit">
            <TextField
                label="Id франчайзи"
                source="id"
            />
            <TextField
                label="Имя франчайзи"
                source="name"
            />
            <TextField
                label="Сумма поступлений с НДС за отчётный месяц"
                source="revenue"
            />
            <TextField
                label="Сумма поступлений без НДС"
                source="withoutNDS"
            />
            <TextField
                label="Сумма вознаграждения франчайзи"
                source="commission"
            />
        </Datagrid>
    </List>
);
