import { Filter, SelectInput, TextInput } from 'react-admin';
import React from 'react';
import { Button } from '@material-ui/core';

const ExternalProfilesFilter = props => (
    <Filter {...props}>
        <SelectInput
            label="Поле"
            source="field"
            choices={[
                { id: 'id', name: 'Id' },
                { id: 'user_id', name: 'Id юзера' },
                { id: 'status', name: 'Статус' },
                { id: 'phone', name: 'Телефон' },
                { id: 'uuid', name: 'uuid' },
                { id: 'partner_service_id', name: 'Id партнерского сервиса' },
                { id: 'country', name: 'Страна' },
                { id: 'locale', name: 'Язык' },
            ]}
            alwaysOn
        />
        <TextInput
            label="Поиск"
            source="q"
            alwaysOn
        />
    </Filter>
);

export default ExternalProfilesFilter;
