import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';
import config from './config';
import decodeJwt from 'jwt-decode';
const env = process.env.REACT_APP_CUSTOM_NODE_ENV;

export default (type, params) => {
    // called when the user attempts to log in
    const URL = config[env].url;
    if (type === AUTH_LOGIN) {
        const { username, password } = params;
        const request = new Request(URL + 'iam/employees/login', {
            method: 'POST',
            body: JSON.stringify({ email: username, password: password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ token }) => {
                const decodedToken = decodeJwt(token);
                localStorage.setItem('token', token);
                localStorage.setItem('permissions', decodedToken.role);
                // console.log(decodedToken.role)
            });
        // accept all username/password combinations
        // const { username } = params;
        // localStorage.setItem('token', username);
        // return Promise.resolve();
    }
    // called when the user clicks on the logout button
    if (type === AUTH_LOGOUT) {
        localStorage.removeItem('token');
        localStorage.removeItem('permissions');
        return Promise.resolve();
    }
    // called when the API returns an error
    if (type === AUTH_ERROR) {
        const { status } = params;
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            return Promise.reject();
        }
        return Promise.resolve();
    }
    // called when the user navigates to a new Locations
    if (type === AUTH_CHECK) {
        return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
    }
    if (type === AUTH_GET_PERMISSIONS) {
        const permissions = localStorage.getItem('permissions');
        return permissions ? Promise.resolve(permissions) : Promise.reject();
    }
    return Promise.reject('Unknown method');
};
