import React from 'react';
import moment from 'moment';
import { reduxForm } from 'redux-form';
import { DateInput } from 'react-admin-date-inputs';

const TimeSelector = props => {
    const { handleChange } = props;
    return (
        <form
            onChange={handleChange}
            style={{ display: 'flex' }}
        >
            <DateInput
                label="C"
                source="startDate"
                options={{ format: 'dd.MM.YYYY' }}
                className="selector"
            />
            <DateInput
                label="До"
                source="endDate"
                options={{ format: 'dd.MM.YYYY' }}
                className="selector"
            />
        </form>
    );
};

export default reduxForm({
    form: 'simple',
    initialValues: {
        startDate: moment().startOf('month').toDate(),
        endDate: moment().startOf('day').toDate(),
    },
    enableReinitialize: false,
})(TimeSelector);
