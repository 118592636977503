import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import { showNotification as showNotificationAction } from 'react-admin';
import { push as pushAction } from 'react-router-redux';
import config from '../config';

const env = process.env.REACT_APP_CUSTOM_NODE_ENV;

class ReturnButton extends Component {
    state = {
        disabled: false,
    };

    async setDisabled(disabled) {
        await this.setState({
            disabled: disabled,
        });
    }

    handleClick = () => {
        const { record, showNotification } = this.props;
        const token = localStorage.getItem('token');
        const headers = new Headers({
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        });
        this.setDisabled(true)
            .then(() => {
                showNotification('Запрос отправлен');
                return fetch(config[env].url + 'admin/orders/returnorder', {
                    method: 'POST',
                    body: JSON.stringify(record),
                    headers,
                });
            })
            .then(async response => {
                const data = await response.json();
                if (response.status === 200) {
                    showNotification('OK');
                    await this.setDisabled(true);
                } else {
                    showNotification(data.message, 'warning');
                    await this.setDisabled(false);
                }
            })
            .catch(e => {
                this.setDisabled(false).then();
                console.error(e);
                showNotification('Ошибка: что-то пошло не так', 'warning');
            });
    };

    render() {
        return (
            <Button
                onClick={this.handleClick}
                variant="contained"
                color={'primary'}
                disabled={
                    this.state.disabled ||
                    (this.props.record &&
                        (this.props.record.status === 'returned_suc' ||
                            this.props.record.status === 'voided' ||
                            this.props.record.status === 'canceled'))
                }
            >
                Отмена{' '}
            </Button>
        );
    }
}

ReturnButton.propTypes = {
    push: PropTypes.func,
    record: PropTypes.object,
    showNotification: PropTypes.func,
};

export default connect(null, {
    showNotification: showNotificationAction,
    push: pushAction,
})(ReturnButton);
