import React, { Component } from 'react';
import shallowEqual from 'shallowequal';
import { connectAdvanced } from 'react-redux';
import * as actions from './testStationActions';
import Station from './components/Station';
import StationSwitcher from './components/StationSwitcher';
import './TestStation.css';
import Loader from '../loader/Loader';
import DebounceButton from './components/DebounceButton';
import { delayAction } from '../utils';

class TestStation extends Component {
    onSelect = sno => {
        this.props.action.startLoading();
        delayAction(() => {
            this.props.action.choice(sno);
            this.props.history.replace({
                search: `?sno=${sno}`,
            });
        }, 1500);
    };

    refresh = () => {
        this.props.action.startLoading();
        delayAction(() => {
            this.props.action.loadSnoList();
            this.props.action.loadSlotsList();
        }, 1500);
    };

    componentDidMount() {
        // this.timer = setInterval(this._tick, 6000);
        // this.timerSlow = setInterval(this._tickSlow, 600000);
        const snoParam = new URLSearchParams(this.props.location.search).get('sno');

        snoParam && this.onSelect(snoParam);
        // this.props.action.connect();
    }

    componentWillUnmount() {
        // clearInterval(this.timer);
        // clearInterval(this.timerSlow);
        // this.props.action.disconnect();
    }

    render() {
        const { snoList, slotsList, logsList, active, isLoading } = this.props.state;
        const {
            choice,
            loadSnoList,
            extract,
            restart,
            open,
            updateAdminStatus,
            updateConflictStatus,
            changeAllStatuses,
        } = this.props.action;

        return (
            <div style={{ position: 'relative' }}>
                {isLoading && (
                    <div className="loader-container">
                        <Loader />
                    </div>
                )}
                <div className={'wrapper'}>
                    <div className="testStation-row">
                        <StationSwitcher
                            snoList={snoList}
                            choice={this.onSelect}
                            active={active}
                        />
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <div>Обновить</div>
                            <DebounceButton onRefresh={this.refresh} />
                        </div>
                    </div>
                    <Station
                        active={active}
                        open={open}
                        loadSnoList={loadSnoList}
                        snoList={snoList}
                        slotsList={slotsList}
                        logsList={logsList}
                        extract={extract}
                        updateAdminStatus={updateAdminStatus}
                        updateConflictStatus={updateConflictStatus}
                        changeAllStatuses={changeAllStatuses}
                        restart={restart}
                    />
                </div>
            </div>
        );
    }
}

function selectorFactory(dispatch) {
    let result = {};
    return (nextState, nextOwnProps) => {
        const state = {
            ...nextState.test,
            isLoading: nextState.test.isLoading,
        };
        const action = {
            startLoading: () => actions.startLoading(dispatch),
            loadSlotsList: () => actions.loadSlotsList(dispatch, state.active),
            loadSnoList: () => actions.loadSnoList(dispatch),
            choice: val => actions.choice(dispatch, val, state.socket),
            extract: params => actions.extract(dispatch, params, state),
            loadLogsList: () => actions.loadLogsList(dispatch, state.active, state.datetime),
            connect: () => actions.connect(dispatch, state.active),
            disconnect: () => actions.disconnect(dispatch, state.socket),
            open: params => actions.open(dispatch, params, state),
            updateAdminStatus: params => actions.updateAdminStatus(dispatch, params, state),
            updateConflictStatus: params => actions.updateConflictStatus(dispatch, params, state),
            changeAllStatuses: params => actions.changeAllStatuses(dispatch, params, state),
            restart: params => actions.restart(dispatch, params),
        };
        const nextResult = { ...nextOwnProps, state, action };
        if (!shallowEqual(result, nextResult)) result = nextResult;
        return result;
    };
}
export default connectAdvanced(selectorFactory)(TestStation);
