import React, { Fragment } from 'react';
import { Datagrid, Filter, List, SelectInput, TextField, TextInput } from 'react-admin';
import { DateInput } from 'react-admin-date-inputs';
import moment from 'moment';
import ExportButton from './reports/ExportButton';
import SendButton from './reports/SendButton';
import { CustomPagination, isEmployee, isPartner } from './utils';

const PartnerReportsFilter = props => (
    <Filter {...props}>
        <DateInput
            label="C"
            source="start_date"
            options={{ format: 'dd.MM.YYYY' }}
            isRequired={false}
            alwaysOn
        />
        <DateInput
            label="До"
            source="end_date"
            options={{ format: 'dd.MM.YYYY' }}
            isRequired={false}
            alwaysOn
        />
        {isEmployee(props.permissions) ? (
            <SelectInput
                label="Поле"
                source="field"
                choices={[
                    { id: 'id', name: 'Id' },
                    { id: 'name', name: 'Имя партнера' },
                    { id: 'franchisee', name: 'Франчайзи' },
                ]}
                alwaysOn
            />
        ) : !isPartner(props.permissions) ? (
            <SelectInput
                label="Поле"
                source="field"
                choices={[
                    { id: 'id', name: 'Id' },
                    { id: 'name', name: 'Имя партнера' },
                ]}
                alwaysOn
            />
        ) : null}
        {!isPartner(props.permissions) && (
            <TextInput
                label="Поиск"
                source="q"
                alwaysOn
            />
        )}
        {!isPartner(props.permissions) && (
            <SelectInput
                label="НДС"
                source="vat"
                choices={[
                    { id: 'yes', name: 'Да' },
                    { id: 'no', name: 'Нет' },
                ]}
            />
        )}
    </Filter>
);

const BulkActionButtons = props => (
    <Fragment>
        <ExportButton
            label="Экспортировать"
            {...props}
        />
        <SendButton
            label="Отправить"
            {...props}
        />
    </Fragment>
);

export const PartnerReportsList = ({ permissions, ...props }) => (
    <List
        {...props}
        filters={<PartnerReportsFilter permissions={permissions} />}
        // pagination={false}
        pagination={<CustomPagination />}
        exporter={false}
        filterDefaultValues={{
            start_date: moment().startOf('month').toDate(),
            end_date: moment().startOf('day').toDate(),
        }}
        bulkActionButtons={<BulkActionButtons />}
    >
        <Datagrid rowClick="edit">
            <TextField
                label="Id партнера"
                source="id"
            />
            <TextField
                label="Имя партнера"
                source="name"
            />
            <TextField
                label="Выручка"
                source="revenue"
            />
            <TextField
                label="Комиссия"
                source="commission"
            />
            <TextField
                label="НДС"
                source="vat_payer"
            />
            {isEmployee(permissions) && (
                <TextField
                    label="Франчайзи"
                    source="franchisee"
                />
            )}
        </Datagrid>
    </List>
);
