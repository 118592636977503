import React from 'react';
import {
    Datagrid,
    DateField,
    Filter,
    List,
    SelectInput,
    Show,
    SimpleShowLayout,
    TextField,
    TextInput,
} from 'react-admin';
import { BulkDeleteButtonDisable, CustomPagination } from './utils';
import { JsonField } from './jsonView/JsonField';

const ClientEventsFilter = props => (
    <Filter {...props}>
        <SelectInput
            label="Поле"
            source="field"
            choices={[
                { id: 'id', name: 'Id' },
                { id: 'name', name: 'Имя' },
                { id: 'user_id', name: 'Id пользователя' },
                { id: 'player_id', name: 'Player Id' },
                { id: 'created', name: 'Время создания' },
            ]}
            alwaysOn
        />
        <TextInput
            label="Поиск"
            source="q"
            alwaysOn
        />
    </Filter>
);

export const ClientEventsList = ({ permissions, ...props }) => (
    <List
        {...props}
        filters={<ClientEventsFilter />}
        bulkActionButtons={<BulkDeleteButtonDisable />}
        pagination={<CustomPagination />}
    >
        <Datagrid rowClick="show">
            <TextField
                label="Id"
                source="id"
            />
            <TextField
                label="Имя"
                source="name"
            />
            <TextField
                label="Id пользователя"
                source="user_id"
            />
            <TextField
                label="Player Id"
                source="player_id"
            />
            <DateField
                label="Время создания"
                source="created"
                showTime
                locales="ru-RU"
            />
        </Datagrid>
    </List>
);

export const ClientEventsShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField
                label="Id"
                source="id"
            />
            <TextField
                label="Имя"
                source="name"
            />
            <JsonField
                label="Значение"
                source="value"
                reactJsonOptions={{
                    name: null,
                    collapsed: 1,
                    enableClipboard: true,
                    displayDataTypes: false,
                }}
            />
            <TextField
                label="Id пользователя"
                source="user_id"
            />
            <TextField
                label="Player Id"
                source="player_id"
            />
            <JsonField
                label="Метаданные"
                source="metadata"
                reactJsonOptions={{
                    name: null,
                    collapsed: 1,
                    enableClipboard: true,
                    displayDataTypes: false,
                }}
            />
            <DateField
                label="Время создания"
                source="created"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Последнее обновление"
                source="updated"
                showTime
                locales="ru-RU"
            />
        </SimpleShowLayout>
    </Show>
);
