import React, { useState } from 'react';
import { Datagrid, List, TextField, ExportButton } from 'react-admin';
import { CustomPagination } from '../utils';
import { ModalForm, ProfilesFilter } from './components';
import { Button } from '@material-ui/core';

export const ExternalProfilesList = ({ permissions, ...props }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <List
                {...props}
                filters={<ProfilesFilter />}
                pagination={<CustomPagination />}
                actions={
                    <div>
                        <Button
                            onClick={() => setIsOpen(true)}
                            variant={'text'}
                            color={'primary'}
                        >
                            Найти в My Mts
                        </Button>
                        <ExportButton />
                    </div>
                }
            >
                <Datagrid rowClick="edit">
                    <TextField
                        label="Id"
                        source="id"
                    />
                    <TextField
                        label="Id юзера"
                        source="user_id"
                    />
                    <TextField
                        label="Статус"
                        source="status"
                    />
                    <TextField
                        label="Телефон"
                        source="phone"
                    />
                    <TextField
                        label="UUID"
                        source="uuid"
                    />
                    <TextField
                        label="Id партнерского сервиса"
                        source="partner_service_id"
                    />
                    <TextField
                        label="Страна"
                        source="country"
                    />
                    <TextField
                        label="Язык"
                        source="locale"
                    />
                </Datagrid>
            </List>
            <ModalForm
                isOpen={isOpen}
                setIsOpen={setIsOpen}
            />
        </>
    );
};
export default ExternalProfilesList;
