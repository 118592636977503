import React from 'react';
import {
    Create,
    Datagrid,
    DateField,
    Edit,
    Filter,
    List,
    required,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput,
} from 'react-admin';
import { CustomPagination, ToolbarSave } from './utils';

const StationHoldersFilter = props => (
    <Filter {...props}>
        <SelectInput
            label="Поле"
            source="field"
            choices={[
                { id: 'id', name: 'ID' },
                { id: 'UUID', name: 'UUID' },
                { id: 'type', name: 'Тип' },
                { id: 'prefix', name: 'Префикс' },
                { id: 'created', name: 'Время создания' },
                { id: 'updated', name: 'Последнее обновление' },
            ]}
            alwaysOn
        />
        <TextInput
            label="Поиск"
            source="q"
            alwaysOn
        />
    </Filter>
);

export const StationHoldersList = ({ permissions, ...props }) => (
    <List
        {...props}
        filters={<StationHoldersFilter />}
        sort={{ field: 'id', order: 'DESC' }}
        pagination={<CustomPagination />}
    >
        <Datagrid rowClick="edit">
            <TextField
                label="ID"
                source="id"
            />
            <TextField
                label="UUID"
                source="uuid"
            />
            <TextField
                label="Тип"
                source="type"
            />
            <TextField
                label="Префикс"
                source="prefix"
            />
            <DateField
                label="Время создания"
                source="created"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Последнее обновление"
                source="updated"
                showTime
                locales="ru-RU"
            />
        </Datagrid>
    </List>
);

export const AddStationHolder = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput
                label="Тип"
                source="type"
                validate={required()}
            />
            <TextInput
                label="Префикс"
                source="prefix"
                validate={required()}
            />
        </SimpleForm>
    </Create>
);

export const EditStationHolder = ({ permissions, ...props }) => (
    <Edit {...props}>
        <SimpleForm toolbar={<ToolbarSave />}>
            <TextInput
                disabled
                label="ID"
                source="id"
            />
            <TextInput
                disabled
                label="UUID"
                source="uuid"
            />
            <TextInput
                label="Тип"
                source="type"
                validate={required()}
            />
            <TextInput
                label="Префикс"
                source="prefix"
                validate={required()}
            />
            <TextInput
                disabled
                label="Время создания"
                source="created"
            />
            <TextInput
                disabled
                label="Последнее обновление"
                source="updated"
            />
        </SimpleForm>
    </Edit>
);
