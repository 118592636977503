// in src/MyLayout.js
import React from 'react';
import { Login } from 'react-admin';

const MyLogin = props => (
    <Login
        backgroundImage=""
        {...props}
    />
);

export default MyLogin;
