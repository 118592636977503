import React, { Component } from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';

class RevenueDebtChart extends Component {
    render() {
        const { data } = this.props;
        return (
            <ResponsiveContainer
                width="80%"
                aspect={2}
            >
                <BarChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar
                        dataKey="revenue"
                        name="доход"
                        stackId="a"
                        fill="#54a520"
                    />
                    <Bar
                        dataKey="debt"
                        name="долг"
                        stackId="a"
                        fill="#ff3d64"
                    />
                </BarChart>
            </ResponsiveContainer>
        );
    }
}

export default RevenueDebtChart;
