import { CREATE, DELETE, DELETE_MANY, fetchUtils, GET_LIST, GET_MANY, GET_ONE, HttpError, UPDATE } from 'react-admin';
import { stringify } from 'query-string';
import config from './config';
import { isPartnerService, preparePhotoToUpload } from './utils';

const env = process.env.REACT_APP_CUSTOM_NODE_ENV;

const URL = config[env].url;

/**
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */
const convertDataProviderRequestToHTTP = async (type, resource, params) => {
    const options = {};
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    options.headers.set('Content-Type', 'application/json');
    const token = localStorage.getItem('token');
    const permissions = localStorage.getItem('permissions');
    let url;
    if (permissions === 'franchisee') {
        url = URL + 'franchisee/';
    } else if (permissions === 'partner') {
        url = URL + 'partner/';
    } else if (permissions === 'agent') {
        url = URL + 'agent/';
    } else if (permissions === 'partner_service') {
        url = URL + 'partner_service/';
    } else {
        url = URL + 'admin/';
    }

    options.headers.set('Authorization', `Bearer ${token}`);

    switch (type) {
        case GET_LIST: {
            const { page, perPage } = params.pagination;
            var { field, order } = params.sort;
            const query = {
                sort: field,
                order: order,
                offset: (page - 1) * perPage,
                limit: perPage,
                filter: JSON.stringify(params.filter),
            };
            return { url: `${url}${resource}?${stringify(query)}`, options: options };
        }
        case GET_MANY: {
            if (resource === 'partner_contracts') {
                const query = {
                    filter: JSON.stringify({
                        id: params.ids,
                        ...(isPartnerService(permissions)
                            ? { partner_service: true }
                            : { partner: true }),
                    }),
                };
                return { url: `${url}${resource}?${stringify(query)}`, options: options };
            } else if (resource === 'partner_service_contracts') {
                const query = {
                    filter: JSON.stringify({ id: params.ids, partner_service: true }),
                };
                return { url: `${url}${resource}?${stringify(query)}`, options: options };
            } else {
                const query = {
                    filter: JSON.stringify({ id: params.ids }),
                };
                return { url: `${url}${resource}?${stringify(query)}`, options: options };
            }
        }
        case GET_ONE:
            return { url: `${url}${resource}/${params.id}`, options: options };
        case UPDATE:
            options.method = 'PUT';
            switch (resource) {
                case 'stations':
                    params.data.location_id = parseInt(params.data.location_id);
                    break;
                case 'admin_users':
                    params.data.franchisee_id = parseInt(params.data.franchisee_id);
                    params.data.agent_id = parseInt(params.data.agent_id);
                    params.data.partner_id = parseInt(params.data.partner_id);
                    params.data.partner_service_id = parseInt(params.data.partner_service_id);
                    break;
                case 'partners':
                    params.data.emails =
                        params.data.emails && params.data.emails.length
                            ? params.data.emails.filter(el => el).map(el => el.email)
                            : null;
                    params.data.franchisee_id = parseInt(params.data.franchisee_id);
                    params.data.agent_id = parseInt(params.data.agent_id);
                    break;
                case 'locations':
                    const newPictures =
                        params.data.photo && params.data.photo.length
                            ? params.data.photo.filter(p => p.rawFile instanceof File)
                            : [];
                    const formerPictures = params.data.photo
                        .filter(p => !(p.rawFile instanceof File))
                        .map(p => ({ id: p.id }));
                    const transformedNewPictures = await Promise.all(
                        newPictures.map(preparePhotoToUpload),
                    );
                    params.data.photo = [...transformedNewPictures, ...formerPictures];
                case 'partner_services':
                    if (params.data.hosts && params.data.hosts.length)
                        params.data.hosts = params.data.hosts.map(elem => elem.host);
                    if (!params.data.description) params.data.description = '';
                    delete params.data.created;
                    delete params.data.updated;
            }
            options.body = JSON.stringify(params.data);
            if (resource === 'admin_users') {
                return { url: `${URL}iam/employees/change-role`, options: options };
            } else {
                return { url: `${url}${resource}`, options: options };
            }
        case CREATE:
            options.method = 'POST';
            switch (resource) {
                case 'locations':
                    if (!Object.keys(params.data).length)
                        throw new Error('Невозможно создать пустую локацию');
                    const newPictures =
                        params.data.photo && params.data.photo.length
                            ? params.data.photo.filter(p => p.rawFile instanceof File)
                            : [];
                    const transformedNewPictures = await Promise.all(
                        newPictures.map(preparePhotoToUpload),
                    );
                    params.data.photo = [...transformedNewPictures];
                case 'partners':
                    params.data.emails =
                        params.data.emails && params.data.emails.length
                            ? params.data.emails.filter(el => el).map(el => el.email)
                            : null;
                case 'partner_services':
                    if (params.data.hosts && params.data.hosts.length)
                        params.data.hosts = params.data.hosts.map(elem => elem.host);
                    if (!params.data.description) params.data.description = '';
            }

            options.body = JSON.stringify(params.data);
            if (resource === 'admin_users') {
                return {
                    url: `${URL}iam/employees/register${
                        permissions === 'ge_employee' ? '-employee' : ''
                    }`,
                    options: options,
                };
            } else {
                return { url: `${url}${resource}`, options: options };
            }
        case DELETE:
            options.method = 'DELETE';
            options.body = JSON.stringify(params);
            return { url: `${url}${resource}/${params.id}`, options: options };
        case DELETE_MANY: {
            options.method = 'DELETE';
            const query = {
                filter: JSON.stringify({ id: params.ids }),
            };
            return { url: `${url}${resource}?${stringify(query)}`, options: options };
        }
        default:
            throw new Error(`Unsupported fetch action type ${type}`);
    }
};

/**
 * @param {Object} response HTTP response from fetch()
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} Data Provider response
 */
// eslint-disable-next-line no-unused-vars
const convertHTTPResponseToDataProvider = (response, type, resource, params) => {
    const { status, json } = response;
    switch (type) {
        case GET_LIST:
            return {
                data: json.rows.map(x => x),
                total: json.count,
            };
        case GET_ONE:
            return { data: { ...json, id: json.id } };
        case GET_MANY:
            return {
                data: json.rows.map(x => x),
                total: json.count,
            };
        case UPDATE:
            if (status !== 200)
                return Promise.reject(
                    new HttpError(
                        json.errorMessage.slice(json.errorMessage.search('ERROR'), -1),
                        json.httpStatusCode,
                    ),
                );
            return { data: { ...json, id: json.id } };
        case DELETE:
            return { data: { ...json, id: json.id } };
        case DELETE_MANY:
            return { data: [] };
        case CREATE:
            switch (resource) {
                case 'partner_services':
                    prompt(`Ключ аутентификации клиента:`, json.secret1);
                    prompt(`Ключ ауентификации Callback:`, json.secret2);
            }
            if (status !== 200)
                return Promise.reject(
                    new HttpError(
                        json.errorMessage.slice(json.errorMessage.search('ERROR'), -1),
                        json.httpStatusCode,
                    ),
                );
            return { data: { ...json, id: json.id } };
        default:
            return { data: json };
    }
};

/**
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for response
 */
export default async (type, resource, params) => {
    const { fetchJson } = fetchUtils;
    const { url, options } = await convertDataProviderRequestToHTTP(type, resource, params);
    return fetchJson(url, options).then(response =>
        convertHTTPResponseToDataProvider(response, type, resource, params),
    );
};
