// in src/MyMenu.js
import React from 'react';
import { connect } from 'react-redux';
import { getResources, MenuItemLink, Responsive, WithPermissions } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Divider } from '@material-ui/core';
import { getIconComponent } from './MenuItems/getMenuIcon';
import { MENU_CONFIG } from './MenuItems/MENU_CONFIG';
import { menuItems } from './MenuItems/menuItems';

const styles = {
    root: {}, // Style applied to the MenuItem from material-ui
    active: {}, // Style applied when the menu item is the active one
    icon: {}, // Style applied to the icon
};

// eslint-disable-next-line no-unused-vars
const MyMenu = ({ classes, resources, onMenuClick, logout }) => {
    const checkPermission = (item, userRole) => {
        const itemKey = item.to.slice(1);
        if (MENU_CONFIG[itemKey] && userRole in MENU_CONFIG[itemKey]) {
            return MENU_CONFIG[itemKey][userRole];
        }
        return item.permissions.includes(userRole);
    };

    const filterMenuItems = (items, userRole) => {
        return items.filter(item => item.type === 'divider' || checkPermission(item, userRole));
    };

    const renderMenuItems = items => {
        return items.map((item, index) => {
            if (item.type === 'divider') {
                return <Divider key={`divider-${index}`} />;
            }
            const IconComponent = getIconComponent(item.icon);
            return (
                <MenuItemLink
                    key={item.to}
                    leftIcon={IconComponent ? <IconComponent /> : null}
                    classes={classes}
                    to={item.to}
                    primaryText={item.primaryText}
                    onClick={onMenuClick}
                />
            );
        });
    };
    return (
        <div>
            {/* {resources.map(resource => (
            <MenuItemLink classes={classes} to={`/${resource.name}`} primaryText={resource.name} onClick={onMenuClick} icon={resource.icon} />
        ))}*/}
            <WithPermissions
                render={({ permissions }) => {
                    const filteredItems = filterMenuItems(menuItems, permissions);
                    return (
                        <>
                            {renderMenuItems(filteredItems)}
                            <Responsive
                                small={logout}
                                medium={null}
                            />
                        </>
                    );
                }}
            />
        </div>
    );
};

const mapStateToProps = state => ({
    resources: getResources(state),
});

export default withRouter(connect(mapStateToProps)(withStyles(styles)(MyMenu)));
