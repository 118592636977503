import React from 'react';
import {
    Datagrid,
    DateField,
    Filter,
    List,
    SelectField,
    SelectInput,
    Show,
    SimpleShowLayout,
    TextField,
    TextInput,
} from 'react-admin';
import { BulkDeleteButtonDisable, CustomPagination } from './utils';

const Tele2ChecksFilter = props => (
    <Filter {...props}>
        <SelectInput
            label="Поле"
            source="field"
            choices={[
                { id: 'id', name: 'Id' },
                { id: 'session_id', name: 'Id сессии' },
                { id: 'user_id', name: 'Id пользователя' },
                { id: 'check_code', name: 'Код проверки' },
                { id: 'check_timestamp', name: 'Время проверки' },
                { id: 'update_code', name: 'Код обновления' },
                { id: 'update_timestamp', name: 'Время обновления' },
                { id: 'created', name: 'Время создания' },
                { id: 'updated', name: 'Последнее обновление' },
            ]}
            alwaysOn
        />
        <TextInput
            label="Поиск"
            source="q"
            alwaysOn
        />
    </Filter>
);

export const Tele2ChecksList = ({ permissions, ...props }) => (
    <List
        {...props}
        filters={<Tele2ChecksFilter />}
        bulkActionButtons={<BulkDeleteButtonDisable />}
        pagination={<CustomPagination />}
    >
        <Datagrid rowClick="edit">
            <TextField
                label="Id"
                source="id"
            />
            <TextField
                label="Id сессии"
                source="session_id"
            />
            <TextField
                label="Id пользователя"
                source="user_id"
            />
            <SelectField
                label="Код проверки"
                source="check_code"
                choices={[
                    { id: 'bp_ok', name: 'Оплаченный ТП' },
                    { id: 'bp_have_service', name: 'Есть подписка' },
                    { id: 'bp_not_tele2', name: 'Не Tele2' },
                    { id: 'bp_wrong_tp', name: 'Без ТП и подписки' },
                    { id: 'bp_wrong_params', name: 'Внутренняя ошибка' },
                    { id: 'bp_internal_error', name: 'Служебная ошибка' },
                ]}
                allowEmpty
            />
            <DateField
                label="Время проверки"
                source="check_timestamp"
                showTime
                locales="ru-RU"
            />
            <SelectField
                label="Код обновления"
                source="update_code"
                choices={[
                    { id: 'bp_ok', name: 'Оплаченный ТП' },
                    { id: 'bp_have_service', name: 'Есть подписка' },
                    { id: 'bp_not_tele2', name: 'Не Tele2' },
                    { id: 'bp_wrong_tp', name: 'Без ТП и подписки' },
                    { id: 'bp_wrong_params', name: 'Внутренняя ошибка' },
                    { id: 'bp_internal_error', name: 'Служебная ошибка' },
                ]}
                allowEmpty
            />
            <DateField
                label="Время обновления"
                source="update_timestamp"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Время создания"
                source="created"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Последнее обновление"
                source="updated"
                showTime
                locales="ru-RU"
            />
        </Datagrid>
    </List>
);

export const Tele2ChecksShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField
                label="Id"
                source="id"
            />
            <TextField
                label="Id сессии"
                source="session_id"
            />
            <TextField
                label="Id пользователя"
                source="user_id"
            />
            <SelectField
                label="Код проверки"
                source="check_code"
                choices={[
                    { id: 'bp_ok', name: 'Оплаченный ТП' },
                    { id: 'bp_have_service', name: 'Есть подписка' },
                    { id: 'bp_not_tele2', name: 'Не Tele2' },
                    { id: 'bp_wrong_tp', name: 'Без ТП и подписки' },
                    { id: 'bp_wrong_params', name: 'Внутренняя ошибка' },
                    { id: 'bp_internal_error', name: 'Служебная ошибка' },
                ]}
                allowEmpty
            />
            <DateField
                label="Время проверки"
                source="check_timestamp"
                showTime
                locales="ru-RU"
            />
            <SelectField
                label="Код обновления"
                source="update_code"
                choices={[
                    { id: 'bp_ok', name: 'Оплаченный ТП' },
                    { id: 'bp_have_service', name: 'Есть подписка' },
                    { id: 'bp_not_tele2', name: 'Не Tele2' },
                    { id: 'bp_wrong_tp', name: 'Без ТП и подписки' },
                    { id: 'bp_wrong_params', name: 'Внутренняя ошибка' },
                    { id: 'bp_internal_error', name: 'Служебная ошибка' },
                ]}
                allowEmpty
            />
            <DateField
                label="Время обновления"
                source="update_timestamp"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Время создания"
                source="created"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Последнее обновление"
                source="updated"
                showTime
                locales="ru-RU"
            />
        </SimpleShowLayout>
    </Show>
);
