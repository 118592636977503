import React from 'react';
import { List, Datagrid, TextField, DateField, TextInput, Filter, SelectInput } from 'react-admin';
import { CustomPagination, BulkDeleteButtonDisable } from './utils';

const PlayersFilter = props => (
    <Filter {...props}>
        <SelectInput
            label="Поле"
            source="field"
            choices={[
                { id: 'id', name: 'Id' },
                { id: 'user_id', name: 'Id юзера' },
                { id: 'player_id', name: 'Player Id' },
                { id: 'created', name: 'Время создания' },
                { id: 'updated', name: 'Последнее обновление' },
                { id: 'deleted', name: 'Время удаления' },
            ]}
            alwaysOn
        />
        <TextInput
            label="Поиск"
            source="q"
            alwaysOn
        />
    </Filter>
);

export const PlayersList = props => (
    <List
        {...props}
        filters={<PlayersFilter />}
        bulkActionButtons={<BulkDeleteButtonDisable />}
        pagination={<CustomPagination />}
    >
        <Datagrid rowClick="edit">
            <TextField
                label="Id"
                source="id"
            />
            <TextField
                label="Id юзера"
                source="user_id"
            />
            <TextField
                label="Player Id"
                source="player_id"
            />
            <DateField
                label="Время создания"
                source="created"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Последнее обновление"
                source="updated"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Время удаления"
                source="deleted"
                showTime
                locales="ru-RU"
            />
        </Datagrid>
    </List>
);
