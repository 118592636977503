import React, { Component } from 'react';
import shallowEqual from 'shallowequal';
import { connectAdvanced } from 'react-redux';
import * as actions from './chartsActions';
import RevenueDebtChart from './components/RevenueDebtChart';
import TimeSelector from './components/TimeSelector';
import './Charts.css';

class Charts extends Component {
    componentDidMount() {
        this.props.action.loadData(this.props.state.params);
    }

    submit = values => {
        this.props.action.changeParams(values);
        this.props.action.loadData(values);
    };

    render() {
        const { data } = this.props.state;
        // console.log(data)
        return (
            <>
                <TimeSelector onChange={this.submit} />
                <RevenueDebtChart data={data} />
            </>
        );
    }
}

function selectorFactory(dispatch) {
    let result = {};
    return (nextState, nextOwnProps) => {
        const state = nextState.charts;
        const action = {
            loadData: params => actions.loadData(dispatch, params),
            changeParams: params => actions.changeParams(dispatch, params),
        };
        const nextResult = { ...nextOwnProps, state, action };
        if (!shallowEqual(result, nextResult)) result = nextResult;
        return result;
    };
}
export default connectAdvanced(selectorFactory)(Charts);
