export const localeOptions = [
    { id: 'ru', name: 'Русский' },
    // { id: 'kz', name: 'Казахский' },
    { id: 'az', name: 'Азербайджанский' },
    // { id: 'uz', name: 'Узбекский' },
    { id: 'en', name: 'English' },
    { id: 'pt', name: 'Português' },
    { id: 'es', name: 'Español' },
    { id: 'ar', name: 'Arabic' },
];
