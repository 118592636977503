import moment from 'moment';
import logo from '../../Logo';

/**
 *  @param {{[key: string]: string[][]}} schedule
 *  @param {string} timezone
 */
export const changeScheduleTimeZone = (schedule, timezone) => {
    return Object.entries(schedule).reduce((acc, [key, value]) => {
        if (!value.length) {
            return { ...acc, [key]: [] };
        }
        const correctValue = value.map(intervals => {
            return intervals.map(time => {
                if (moment(time, 'HH:mm').isValid()) {
                    return moment(time, 'HH:mm').format('HH:mm') + timezone;
                }
                return time;
            });
        });
        return { ...acc, [key]: correctValue };
    }, {});
};
