import React from 'react';
import { Edit, ReferenceInput, SelectInput, SimpleForm } from 'react-admin';
import { ToolbarSaveAllDeleteAdmin } from '../utils';

const optionRenderer = choice => `${choice.id} (${choice.name})`;

export const EditPartnerServiceContract = ({ permissions, ...props }) => {
    return (
        <Edit {...props}>
            <SimpleForm toolbar={<ToolbarSaveAllDeleteAdmin permissions={permissions} />}>
                <ReferenceInput
                    perPage={10000}
                    label="Сервисный партнер"
                    source="partner_service_id"
                    reference="partner_services"
                    allowEmpty={false}
                >
                    <SelectInput optionText={optionRenderer} />
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    );
};
