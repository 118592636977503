import React from 'react';
import {
    Create,
    Datagrid,
    DateField,
    Edit,
    Filter,
    FormDataConsumer,
    List,
    NumberInput,
    ReferenceField,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput,
    AutocompleteInput,
} from 'react-admin';
import { DateTimeInput } from 'react-admin-date-inputs';
import {
    BulkDeleteButtonAdmin,
    CustomPagination,
    isEmployee,
    isFranchisee,
    isPartnerService,
    ToolbarSaveAllDeleteAdmin,
} from './utils';

const StationsFilter = props => (
    <Filter {...props}>
        <SelectInput
            label="Поле"
            source="field"
            choices={[
                { id: 'sno', name: 'Sno' },
                { id: 'location_id', name: 'Id локации' },
                { id: 'owner_type', name: 'Тип контракта' },
                { id: 'owner_contract_id', name: 'Id контракта' },
                { id: 'model', name: 'Модель' },
                { id: 'qr', name: 'Qr-код' },
                { id: 'holder_prefix', name: 'Префикс' },
                { id: 'holder_short_number', name: 'Короткий номер' },
                { id: 'status', name: 'Статус' },
                { id: 'last_message', name: 'Последнее сообщение' },
                { id: 'created', name: 'Время создания' },
                { id: 'updated', name: 'Последнее обновление' },
            ]}
            alwaysOn
        />
        <TextInput
            label="Поиск"
            source="q"
            alwaysOn
        />
    </Filter>
);

export const ContractIdField = ({ record = {} }) => {
    if (record.ff_contract && record.ff_contract.franchisee)
        return (
            <span>
                {record.ff_contract.id} ({record.ff_contract.franchisee.name})
            </span>
        );
    else if (record.partner_service_contract && record.partner_service_contract.partner_service)
        return (
            <span>
                {record.partner_service_contract.id} (
                {record.partner_service_contract.partner_service.name})
            </span>
        );
    return <span>{record.owner_contract_id}</span>;
};

ContractIdField.defaultProps = { label: 'Id контракта' };

const LocationIdField = params => {
    if (params.record)
        return (
            <span>
                {params.record.id} ({params.record.name})
            </span>
        );
    else return <span />;
};
LocationIdField.defaultProps = { label: 'Локация' };

export const StationsList = ({ permissions, ...props }) => (
    <List
        {...props}
        filters={<StationsFilter />}
        bulkActionButtons={<BulkDeleteButtonAdmin permissions={permissions} />}
        pagination={<CustomPagination />}
    >
        <Datagrid rowClick="edit">
            <TextField
                label="Sno"
                source="sno"
            />
            <ReferenceField
                label="Локация"
                source="location_id"
                reference="locations"
                linkType={false}
                allowEmpty
            >
                <LocationIdField />
            </ReferenceField>
            {isEmployee(permissions) && (
                <ContractIdField
                    label="Id контракта"
                    source="owner_contract_id"
                />
            )}
            <TextField
                label="SIM"
                source="sim"
            />
            <TextField
                label="Модель"
                source="model"
            />
            {(isEmployee(permissions) || isFranchisee(permissions)) && (
                <TextField
                    label="Дизайн"
                    source="design"
                />
            )}
            <TextField
                label="QR"
                source="station_qr"
            />
            <TextField
                label="Префикс"
                source="holder_prefix"
            />
            <TextField
                label="Короткий номер"
                source="holder_short_number"
            />
            <TextField
                label="Статус"
                source="status"
            />
            <TextField
                label="Количество слотов"
                source="capacity"
            />
            <DateField
                label="Последнее сообщение"
                source="last_message"
                showTime
                locales="ru-RU"
            />
            {isEmployee(permissions) && (
                <DateField
                    label="Total mismatch"
                    source="total_mismatch"
                    showTime
                    locales="ru-RU"
                />
            )}
            <DateField
                label="Время создания"
                source="created"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Последнее обновление"
                source="updated"
                showTime
                locales="ru-RU"
            />
        </Datagrid>
    </List>
);

const optionRenderer = choice => `${choice.id} (${choice.name})`;
const filterToQuery = searchText => ({ q: searchText, field: 'name' });

export const AddStation = ({ permissions, ...props }) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput
                label="Sno"
                source="sno"
                validate={required()}
            />
            <ReferenceInput
                perPage={10}
                label="Локация"
                source="location_id"
                reference="locations"
                filter={{
                    free_loc:
                        permissions === 'franchisee' ||
                        permissions === 'partner' ||
                        permissions === 'partner_service',
                }}
                filterToQuery={filterToQuery}
                allowEmpty
            >
                <AutocompleteInput optionText={optionRenderer} />
            </ReferenceInput>
            {isEmployee(permissions) && (
                <>
                    <SelectInput
                        label="Тип пользователя"
                        source="owner_type"
                        choices={[
                            { id: 'ff_contract', name: 'Контракт франчайзи' },
                            { id: 'partner_service', name: 'Контракт сервисного партнера' },
                        ]}
                    />
                    <div>
                        <FormDataConsumer>
                            {({ formData, ...rest }) =>
                                formData.owner_type === 'ff_contract' ? (
                                    <ReferenceInput
                                        key={formData.owner_type}
                                        perPage={10000}
                                        label="Id контракта франчайзи"
                                        source="owner_contract_id"
                                        reference="ff_contracts"
                                        allowEmpty
                                        parse={value => value || null}
                                    >
                                        <SelectInput optionText={data => `${data.id}`} />
                                    </ReferenceInput>
                                ) : (
                                    <ReferenceInput
                                        key={formData.owner_type}
                                        perPage={10000}
                                        label="Id контракта сервисного партнера"
                                        source="owner_contract_id"
                                        reference="partner_service_contracts"
                                        allowEmpty
                                        parse={value => value || null}
                                    >
                                        <SelectInput optionText={data => `${data.id}`} />
                                    </ReferenceInput>
                                )
                            }
                        </FormDataConsumer>
                    </div>
                </>
            )}
            <TextInput
                label="Модель"
                source="model"
            />
            {(isEmployee(permissions) || isFranchisee(permissions)) && (
                <SelectInput
                    label="Дизайн"
                    source="design"
                    choices={[
                        { id: 'GE', name: 'GE' },
                        { id: 'UR-GE', name: 'UR-GE' },
                        { id: 'UR', name: 'UR' },
                    ]}
                />
            )}
            <TextInput
                label="SIM"
                source="sim"
            />
            <TextInput
                label="QR"
                source="station_qr"
                allowEmpty
                parse={value => value}
            />
            <TextInput
                disabled={isPartnerService(permissions)}
                label="Префикс"
                source="holder_prefix"
                allowEmpty
                parse={value => value}
            />
            {/*//TODO*/}
            {/*<ReferenceInput*/}
            {/*    perPage={100}*/}
            {/*    label="Префикс"*/}
            {/*    source="holder_prefix"*/}
            {/*    reference="station_holders"*/}
            {/*    allowEmpty*/}
            {/*    parse={value => value}*/}
            {/*    queryOptions={{ meta: { findBy: 'prefix' } }}*/}
            {/*>*/}
            {/*    <SelectInput optionText={data => `${data.prefix}`} />*/}
            {/*</ReferenceInput>*/}
            <TextInput
                label="Короткий номер"
                source="holder_short_number"
            />
            <SelectInput
                label="Статус"
                source="status"
                choices={[
                    { id: 'online', name: 'online' },
                    { id: 'offline', name: 'offline' },
                    { id: 'pre_offline', name: 'pre_offline' },
                ]}
            />
            <NumberInput
                label="Количество слотов"
                source="capacity"
            />
        </SimpleForm>
    </Create>
);

export const EditStation = ({ permissions, ...props }) => (
    <Edit {...props}>
        <SimpleForm toolbar={<ToolbarSaveAllDeleteAdmin permissions={permissions} />}>
            <TextInput
                disabled
                label="Id"
                source="id"
            />
            <TextInput
                disabled
                label="Sno"
                source="sno"
            />
            <ReferenceInput
                perPage={10}
                label="Локация"
                source="location_id"
                reference="locations"
                filter={{
                    free_loc:
                        permissions === 'franchisee' ||
                        permissions === 'partner' ||
                        permissions === 'partner_service',
                }}
                filterToQuery={filterToQuery}
                allowEmpty
            >
                <AutocompleteInput optionText={optionRenderer} />
            </ReferenceInput>

            {isEmployee(permissions) && (
                <>
                    <SelectInput
                        label="Тип пользователя"
                        source="owner_type"
                        choices={[
                            { id: 'ff_contract', name: 'Контракт франчайзи' },
                            { id: 'partner_service', name: 'Контракт сервисного партнера' },
                        ]}
                    />
                    <div>
                        <FormDataConsumer>
                            {({ formData, ...rest }) =>
                                formData.owner_type === 'ff_contract' ? (
                                    <ReferenceInput
                                        key={formData.owner_type}
                                        perPage={10000}
                                        label="Id контракта франчайзи"
                                        source="owner_contract_id"
                                        reference="ff_contracts"
                                        allowEmpty
                                        parse={value => value || null}
                                    >
                                        <SelectInput optionText={data => `${data.id}`} />
                                    </ReferenceInput>
                                ) : (
                                    <ReferenceInput
                                        key={formData.owner_type}
                                        perPage={10000}
                                        label="Id контракта сервисного партнера"
                                        source="owner_contract_id"
                                        reference="partner_service_contracts"
                                        allowEmpty
                                        parse={value => value || null}
                                    >
                                        <SelectInput optionText={data => `${data.id}`} />
                                    </ReferenceInput>
                                )
                            }
                        </FormDataConsumer>
                    </div>
                </>
            )}
            <TextInput
                disabled={isPartnerService(permissions)}
                label="Модель"
                source="model"
            />
            {(isEmployee(permissions) || isFranchisee(permissions)) && (
                <SelectInput
                    label="Дизайн"
                    source="design"
                    choices={[
                        { id: 'GE', name: 'GE' },
                        { id: 'UR-GE', name: 'UR-GE' },
                        { id: 'UR', name: 'UR' },
                    ]}
                />
            )}
            <TextInput
                disabled={isPartnerService(permissions)}
                label="SIM"
                source="sim"
            />
            <TextInput
                // disabled={isPartnerService(permissions)}
                label="QR"
                source="station_qr"
                allowEmpty
                parse={value => value}
            />
            <TextInput
                disabled={isPartnerService(permissions)}
                label="Префикс"
                source="holder_prefix"
                allowEmpty
                parse={value => value}
            />
            {/*//TODO*/}
            {/*<ReferenceInput*/}
            {/*    disabled={isPartnerService(permissions)}*/}
            {/*    perPage={100}*/}
            {/*    label="Префикс"*/}
            {/*    source="holder_prefix"*/}
            {/*    reference="station_holders"*/}
            {/*    allowEmpty*/}
            {/*    parse={value => value}*/}
            {/*    queryOptions={{ meta: { findBy: 'prefix' } }}*/}
            {/*>*/}
            {/*    <SelectInput optionText={data => `${data.prefix}`} />*/}
            {/*</ReferenceInput>*/}
            <TextInput
                disabled={isPartnerService(permissions)}
                label="Короткий номер"
                source="holder_short_number"
            />
            <SelectInput
                disabled={isPartnerService(permissions)}
                label="Статус"
                source="status"
                choices={[
                    { id: 'online', name: 'online' },
                    { id: 'offline', name: 'offline' },
                    { id: 'pre_offline', name: 'pre_offline' },
                ]}
            />
            <NumberInput
                disabled={isPartnerService(permissions)}
                label="Количество слотов"
                source="capacity"
            />
            <DateTimeInput
                disabled={isPartnerService(permissions)}
                label="Последнее сообщение"
                source="last_message"
                options={{ format: 'dd.MM.YYYY HH:mm', ampm: false }}
            />
            {isEmployee(permissions) && (
                <DateTimeInput
                    label="Total mismatch"
                    source="total_mismatch"
                    options={{ format: 'dd.MM.YYYY HH:mm', ampm: false }}
                />
            )}
            <TextInput
                disabled
                label="Время создания"
                source="created"
                showTime
            />
            <TextInput
                disabled
                label="Последнее обновление"
                source="updated"
                showTime
            />
        </SimpleForm>
    </Edit>
);
