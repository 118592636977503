const validIpAddressRegex =
    /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]).){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;

const validHostnameRegex =
    /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]).)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$/;

const validURLRegex =
    /https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

const ipOrHostValidation = (value, allValues) => {
    const isHost = validHostnameRegex.test(value);
    const isIp = validIpAddressRegex.test(value);

    if (isHost || isIp) return undefined;
    return 'Введите корректный IP адресс или хост';
};

const urlValidation = (value, allValues) => {
    if (validURLRegex.test(value)) return undefined;
    return 'Введите корректный URL';
};

module.exports = {
    ipOrHostValidation,
    urlValidation,
};
