import moment, { utc } from 'moment';

const FORMAT = 'HH:mm';
const timeStringToDate = (time, timezone) => {
    if (timezone) {
        return moment(time, FORMAT).utcOffset(timezone).toDate();
    }
    return moment(time, FORMAT).toDate();
};

const dateToTimeString = (date, timezone) => {
    return moment(date).format(FORMAT) + timezone;
};

export { timeStringToDate, dateToTimeString };
