import React, { useEffect } from 'react';
import { TextInput } from 'react-admin';
import { Button } from '@material-ui/core';
import config from '../../config.json';
import styles from './styles';
import { withStyles } from '@material-ui/core/styles';
import { change, getFormValues } from 'redux-form';
import { changeScheduleTimeZone, timeStringToDate } from '../utils';
import moment from 'moment';
import { connect } from 'react-redux';

const Timezone = withStyles(styles)(props => {
    const { input, record, classes, meta, currentState } = props;
    const { dispatch, form } = meta;
    const { latitude, longitude } = record;

    useEffect(() => {
        if (!record.timezone) {
            record.timezone = moment().format('Z');
        }
    }, []);
    const detectTimezone = async () => {
        if (latitude && longitude) {
            const env = process.env.REACT_APP_CUSTOM_NODE_ENV;
            const URL = config[env].url;
            const token = localStorage.getItem('token');
            const headers = new Headers({ Accept: 'application/json' });
            headers.set('Authorization', `Bearer ${token}`);
            const permissions = localStorage.getItem('permissions');
            const data = await fetch(
                `${URL}${permissions}/locations/detect-timezone?latitude=${latitude}&longitude=${longitude}`,
                {
                    method: 'GET',
                    headers,
                },
            )
                .then(res => res.json())
                .then(data => data);

            input.onChange(data.timezone);
        }
    };

    const handleChange = value => {
        input.onChange(value);
    };

    const handleBlur = e => {
        const timezone = e.target.value;
        if (moment(timezone, 'Z').isValid() && currentState.schedule) {
            dispatch(
                change(form, 'schedule', changeScheduleTimeZone(currentState.schedule, timezone)),
            );
        }
    };

    return (
        <div className={classes.row}>
            <TextInput
                input={{
                    value: input.value,
                    name: input.name,
                    onFocus: () => {},
                    onChange: handleChange,
                    onBlur: handleBlur,
                }}
                meta={{ ...meta }}
                // onBlur={handleBlur}
            />
            <Button
                variant={'contained'}
                disabled={!latitude || !longitude || input.value}
                onClick={detectTimezone}
            >
                Определить временную зону
            </Button>
        </div>
    );
});

export default connect(state => ({
    currentState: state.form['record-form'].values,
}))(Timezone);
