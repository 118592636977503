import React from 'react';
import moment from 'moment';

function Logs({ logsList }) {
    if (!logsList || logsList.length === 0) {
        return null;
    }
    return (
        <div className="logs">
            {logsList.length > 0 &&
                logsList.map((log, i) => {
                    const { message, level, timestamp, metadata } = log;
                    return (
                        <div
                            className={`log log--${level}`}
                            key={i}
                        >
                            <span className="log__timestamp">
                                {moment(timestamp).format('HH:mm DD.MM.YYYY')}
                            </span>
                            <span className="log__level">{level}</span>
                            <span className="log__message">{message}</span>
                            <span className="log__metadata">
                                {metadata ? <pre>{JSON.stringify(metadata, null, 2)}</pre> : ''}
                            </span>
                        </div>
                    );
                })}
        </div>
    );
}

export default Logs;
