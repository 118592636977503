import React from 'react';
import {
    ArrayField,
    ChipField,
    Datagrid,
    DateField,
    Edit,
    email,
    Filter,
    List,
    SelectField,
    SelectInput,
    SimpleForm,
    SingleFieldList,
    TextField,
    TextInput,
} from 'react-admin';
import { BulkDeleteButtonAdmin, CustomPagination, isGeEmployee, ToolbarSave } from './utils';
import { tariffSpecialOptions } from './constants/tariffs';
import { countryOptions } from './constants/countries';
import { localeOptions } from './constants/locales';

const ProfilesFilter = props => (
    <Filter {...props}>
        <SelectInput
            label="Поле"
            source="field"
            choices={[
                { id: 'user_id', name: 'Id юзера' },
                { id: 'name', name: 'Имя' },
                { id: 'email', name: 'Email' },
                { id: 'status', name: 'Статус' },
                { id: 'phone', name: 'Телефон' },
                { id: 'phone_operator', name: 'Оператор' },
                { id: 'bonus_account', name: 'Бонусный аккаунт' },
                { id: 'invitee_of', name: 'Приглашен кем' },
                { id: 'inviter_of', name: 'Пригласивший кого' },
                { id: 'token', name: 'Токен' },
                { id: 'dtype', name: 'Тип повербанка' },
                { id: 'card_holder', name: 'Держатель карты' },
                { id: 'card_number', name: 'Номер карты' },
                { id: 'created', name: 'Время создания' },
                { id: 'updated', name: 'Последнее обновление' },
                { id: 'deleted', name: 'Время удаления' },
            ]}
            alwaysOn
        />
        <TextInput
            label="Поиск"
            source="q"
            alwaysOn
        />
    </Filter>
);

export const ProfilesList = ({ permissions, ...props }) => (
    <List
        {...props}
        filters={<ProfilesFilter />}
        bulkActionButtons={<BulkDeleteButtonAdmin permissions={permissions} />}
        pagination={<CustomPagination />}
    >
        <Datagrid rowClick="edit">
            <TextField
                label="Id юзера"
                source="user_id"
            />
            <TextField
                label="Имя"
                source="name"
            />
            <TextField
                label="Email"
                source="email"
            />
            <TextField
                label="Статус"
                source="status"
            />
            <TextField
                label="Телефон"
                source="phone"
            />
            <SelectField
                label="Оператор"
                source="phone_operator"
                choices={[{ id: 'rus_tele2', name: 'Тele2 RUS' }]}
                allowEmpty
            />
            <SelectField
                source="special_tariff"
                label="Специальный тариф"
                choices={tariffSpecialOptions}
                allowEmpty
            />
            <TextField
                label="Бонусный аккаунт"
                source="bonus_account"
            />
            <TextField
                label="Токен"
                source="token"
            />
            <TextField
                label="Метод"
                source="payment_method"
            />
            <TextField
                label="Страна"
                source="country"
            />
            <TextField
                label="Язык"
                source="locale"
            />
            <TextField
                label="Держатель карты"
                source="card_holder"
            />
            <TextField
                label="Номер карты"
                source="card_number"
            />
            {isGeEmployee(permissions) && (
                <ArrayField
                    label="Player ids"
                    source="players"
                >
                    <SingleFieldList linkType={false}>
                        <ChipField
                            clickable={false}
                            source="player_id"
                        />
                    </SingleFieldList>
                </ArrayField>
            )}
            <TextField
                label="Рейтинг"
                source="rating"
            />
            <DateField
                label="Время создания"
                source="created"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Последнее обновление"
                source="updated"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Время удаления"
                source="deleted"
                showTime
                locales="ru-RU"
            />
        </Datagrid>
    </List>
);

const BonusField = ({ record, source, permissions }) => {
    if (record[source]) {
        const sourceKeys = Object.keys(record[source]);
        if (isGeEmployee(permissions)) {
            ['RUB', 'AZN', 'KZT', 'BRL', 'UZS', 'AED'].forEach(k => {
                if (record[source][k] == null) {
                    sourceKeys.push(k);
                }
            });
        }
        if (sourceKeys.length) {
            return [
                ...sourceKeys.map(k => [
                    <TextInput
                        label={`Бонусный счет в ${k}`}
                        source={`${source}.${k}`}
                        disabled={!isGeEmployee(permissions)}
                    />,
                    <br />,
                ]),
            ];
        }
    }
    return null;
};

export const EditProfile = ({ permissions, ...props }) => (
    <Edit {...props}>
        <SimpleForm toolbar={<ToolbarSave />}>
            <TextInput
                disabled
                label="Id юзера"
                source="user_id"
            />
            <TextInput
                label="Имя"
                source="name"
            />
            <TextInput
                label="Email"
                source="email"
                validate={email()}
            />
            <SelectInput
                label="Статус"
                source="status"
                choices={[
                    { id: 'active', name: 'active' },
                    { id: 'blocked', name: 'blocked' },
                    { id: 'inactive', name: 'inactive' },
                ]}
            />
            <TextInput
                disabled
                label="Телефон"
                source="phone"
            />
            <SelectInput
                label="Оператор"
                source="phone_operator"
                choices={[{ id: 'rus_tele2', name: 'Тele2 RUS' }]}
                disabled
            />
            <SelectInput
                label="Специальный тариф"
                source="special_tariff"
                choices={tariffSpecialOptions}
                allowEmpty
            />
            <TextInput
                label="Бонусный аккаунт"
                source="bonus_account"
            />
            <TextInput
                disabled
                label="Токен"
                source="token"
            />
            <TextInput
                label="Метод"
                source="payment_method"
            />
            <SelectInput
                label="Страна"
                source="country"
                choices={countryOptions}
                allowEmpty
            />
            <SelectInput
                label="Язык"
                source="locale"
                choices={localeOptions}
                allowEmpty
            />
            <TextInput
                disabled
                label="Держатель карты"
                source="card_holder"
            />
            <TextInput
                disabled
                label="Номер карты"
                source="card_number"
            />
            {isGeEmployee(permissions) && (
                <ArrayField
                    label="Player ids"
                    source="players"
                >
                    <Datagrid>
                        <TextField
                            label="ID"
                            source="player_id"
                        />
                        <TextField
                            label="Время создания"
                            source="created"
                        />
                    </Datagrid>
                </ArrayField>
            )}
            <TextInput
                label="Рейтинг"
                source="rating"
                disabled={!isGeEmployee(permissions)}
            />
            <BonusField
                source="bonus"
                permissions={permissions}
            />
            <TextInput
                disabled
                label="Время создания"
                source="created"
            />
            <TextInput
                disabled
                label="Последнее обновление"
                source="updated"
            />
            <TextInput
                disabled
                label="Время удаления"
                source="updated"
            />
        </SimpleForm>
    </Edit>
);
