import React from 'react';
import { timeStringToDate, dateToTimeString } from '../../../utils';
import { TimeInput } from 'react-admin-date-inputs';

const TimeInputAdapter = props => {
    const {
        isDisabled,
        timezone,
        input: { value, onChange, name },
        ...rest
    } = props;
    const handleChange = date => {
        onChange(dateToTimeString(date, timezone));
    };
    return (
        <TimeInput
            input={{
                value: value ? timeStringToDate(value, timezone) : '',
                name,
                onChange: handleChange,
            }}
            key={name}
            {...rest}
            options={{ ampm: false, disabled: isDisabled }}
        />
    );
};

export default TimeInputAdapter;
