import React from 'react';
import { Route } from 'react-router-dom';
// import Report from './report/Report';
import TestStation from './testStation/TestStation';
import Charts from './charts/Charts';

export default [
    <Route
        key="test"
        exact
        path="/test"
        component={TestStation}
        resource="/test"
    />,
    <Route
        key="charts"
        exact
        path="/charts"
        component={Charts}
        resource="/charts"
    />,
];
