import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    SimpleForm,
    Create,
    Edit,
    TextInput,
    NumberInput,
    Filter,
    SelectInput,
} from 'react-admin';
import { CustomPagination, BulkDeleteButtonAdmin, ToolbarSaveAllDeleteAdmin } from './utils';

const SlotFilter = props => (
    <Filter {...props}>
        <SelectInput
            label="Поле"
            source="field"
            choices={[
                { id: 'sno', name: 'Станция' },
                { id: 'position', name: 'Позиция' },
                { id: 'pksnopos', name: 'Станция:Позиция' },
                { id: 'status', name: 'Статус' },
                { id: 'created', name: 'Время создания' },
                { id: 'updated', name: 'Последнее обновление' },
            ]}
            alwaysOn
        />
        <TextInput
            label="Поиск"
            source="q"
            alwaysOn
        />
    </Filter>
);

export const SlotsList = ({ permissions, ...props }) => (
    <List
        {...props}
        filters={<SlotFilter />}
        bulkActionButtons={<BulkDeleteButtonAdmin permissions={permissions} />}
        pagination={<CustomPagination />}
    >
        <Datagrid rowClick="edit">
            <TextField
                label="Станция"
                source="sno"
            />
            <TextField
                label="Позиция"
                source="position"
            />
            <TextField
                label="Станция:Позиция"
                source="pksnopos"
            />
            <TextField
                label="Статус"
                source="status"
            />
            <DateField
                label="Время создания"
                source="created"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Последнее обновление"
                source="updated"
                showTime
                locales="ru-RU"
            />
        </Datagrid>
    </List>
);

export const AddSlot = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput
                label="Станция"
                source="sno"
            />
            <NumberInput
                label="Позиция"
                source="position"
            />
            <TextInput
                label="Станция:Позиция"
                source="pksnopos"
            />
            <TextInput
                label="Статус"
                source="status"
            />
        </SimpleForm>
    </Create>
);

export const EditSlot = ({ permissions, ...props }) => (
    <Edit {...props}>
        <SimpleForm toolbar={<ToolbarSaveAllDeleteAdmin permissions={permissions} />}>
            <TextInput
                disabled
                label="Id"
                source="id"
            />
            <TextInput
                label="Станция"
                source="sno"
            />
            <NumberInput
                label="Позиция"
                source="position"
            />
            <TextInput
                label="Станция:Позиция"
                source="pksnopos"
            />
            <TextInput
                label="Статус"
                source="status"
            />
            <TextInput
                disabled
                label="Создан"
                source="created"
            />
            <TextInput
                disabled
                label="Последнее обновление"
                source="updated"
            />
        </SimpleForm>
    </Edit>
);
