import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    DateField,
    TextInput,
    Filter,
    SelectInput,
    BooleanField,
} from 'react-admin';
import { CustomPagination, BulkDeleteButtonDisable } from './utils';

const ProfilePaymentMethodsFilter = props => (
    <Filter {...props}>
        <SelectInput
            label="Поле"
            source="field"
            choices={[
                { id: 'id', name: 'Id' },
                { id: 'profile_id', name: 'Id пользователя' },
                { id: 'is_default', name: 'По умолчанию' },
                { id: 'payment_system', name: 'Платежная система' },
                { id: 'payment_method', name: 'Платежный метод' },
                { id: 'payment_type', name: 'Тип' },
                { id: 'holder', name: 'Держатель' },
                { id: 'number', name: 'Номер' },
                { id: 'token', name: 'Токен' },
                { id: 'fingerprint', name: 'Идентификатор' },
                { id: 'created', name: 'Время создания' },
                { id: 'updated', name: 'Последнее обновление' },
                { id: 'deleted', name: 'Время удаления' },
            ]}
            alwaysOn
        />
        <TextInput
            label="Поиск"
            source="q"
            alwaysOn
        />
    </Filter>
);

export const ProfilePaymentMethodsList = props => (
    <List
        {...props}
        filters={<ProfilePaymentMethodsFilter />}
        bulkActionButtons={<BulkDeleteButtonDisable />}
        pagination={<CustomPagination />}
    >
        <Datagrid rowClick="edit">
            <TextField
                label="Id"
                source="id"
            />
            <TextField
                label="Id пользователя"
                source="profile_id"
            />
            <BooleanField
                label="По умолчанию"
                source="is_default"
            />
            <TextField
                label="Платежная система"
                source="payment_system"
            />
            <TextField
                label="Платежный метод"
                source="payment_method"
            />
            <TextField
                label="Тип"
                source="payment_type"
            />
            <TextField
                label="Держатель"
                source="holder"
            />
            <TextField
                label="Номер"
                source="number"
            />
            <TextField
                label="Токен"
                source="token"
            />
            <TextField
                label="Идентификатор"
                source="fingerprint"
            />
            <DateField
                label="Время создания"
                source="created"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Последнее обновление"
                source="updated"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Время удаления"
                source="deleted"
                showTime
                locales="ru-RU"
            />
        </Datagrid>
    </List>
);
