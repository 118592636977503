import React from 'react';
import { Create, ReferenceInput, SelectInput, SimpleForm } from 'react-admin';

// const { ipOrHostValidation, urlValidation } = require('../customValidations/index');
const optionRenderer = choice => `${choice.id} (${choice.name})`;

export const AddPartnerServiceContract = ({ permissions, ...props }) => (
    <Create {...props}>
        <SimpleForm>
            <ReferenceInput
                perPage={10000}
                label="Сервисный партнер"
                source="partner_service_id"
                reference="partner_services"
                allowEmpty={false}
            >
                <SelectInput optionText={optionRenderer} />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);
