import React from 'react';
import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    DateField,
    Edit,
    email,
    Filter,
    List,
    required,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput,
} from 'react-admin';
import { BulkDeleteButtonAdmin, CustomPagination, ToolbarSaveAllDeleteAdmin } from './utils';

const FranchiseeFilter = props => (
    <Filter {...props}>
        <SelectInput
            label="Поле"
            source="field"
            choices={[
                { id: 'id', name: 'Id франчайзи' },
                { id: 'name', name: 'Имя франчайзи' },
                { id: 'details', name: 'Подробности' },
                { id: 'email', name: 'Электронная почта' },
                { id: 'status', name: 'Статус' },
                { id: 'with_fine_fee', name: 'Учет штрафов' },
                { id: 'contact', name: 'Контакты' },
                { id: 'telegram_chat_id', name: 'Id Telegram-чата' },
                { id: 'succ_report_date', name: 'Последний отправленный отчет' },
                { id: 'created', name: 'Время создания' },
                { id: 'updated', name: 'Последнее обновление' },
            ]}
            alwaysOn
        />
        <TextInput
            label="Поиск"
            source="q"
            alwaysOn
        />
    </Filter>
);

export const FranchiseeList = ({ permissions, ...props }) => (
    <List
        {...props}
        filters={<FranchiseeFilter />}
        bulkActionButtons={<BulkDeleteButtonAdmin permissions={permissions} />}
        pagination={<CustomPagination />}
    >
        <Datagrid rowClick="edit">
            <TextField
                label="Id франчайзи"
                source="id"
            />
            <TextField
                label="Имя франчайзи"
                source="name"
            />
            <TextField
                label="Подробности"
                source="details"
            />
            <TextField
                label="Электронная почта"
                source="email"
            />
            <TextField
                label="Статус"
                source="status"
            />
            <BooleanField
                label="Учет штрафов"
                source="with_fine_fee"
            />
            <TextField
                label="Контакты"
                source="contact"
            />
            <TextField
                label="Id Telegram-чата"
                source="telegram_chat_id"
            />
            <DateField
                label="Последний отправленный отчет"
                source="succ_report_date"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Время создания"
                source="created"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Последнее обновление"
                source="updated"
                showTime
                locales="ru-RU"
            />
        </Datagrid>
    </List>
);

export const AddFranchisee = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput
                label="Имя франчайзи"
                source="name"
                validate={required()}
            />
            <TextInput
                label="Подробности"
                source="details"
            />
            <TextInput
                label="Электронная почта"
                source="email"
                validate={[email(), required()]}
            />
            <SelectInput
                label="Статус"
                source="status"
                choices={[
                    { id: 'active', name: 'active' },
                    { id: 'inactive', name: 'inactive' },
                ]}
                validate={required()}
            />
            <BooleanInput
                label="Учет штрафов"
                source="with_fine_fee"
            />
            <TextInput
                label="Контакты"
                source="contact"
                validate={required()}
            />
            <TextInput
                label="Id Telegram-чата"
                source="telegram_chat_id"
            />
        </SimpleForm>
    </Create>
);

export const EditFranchisee = ({ permissions, ...props }) => (
    <Edit {...props}>
        <SimpleForm toolbar={<ToolbarSaveAllDeleteAdmin permissions={permissions} />}>
            <TextInput
                disabled
                label="Id франчайзи"
                source="id"
            />
            <TextInput
                label="Имя франчайзи"
                source="name"
                validate={required()}
            />
            <TextInput
                label="Подробности"
                source="details"
            />
            <TextInput
                label="Электронная почта"
                source="email"
                validate={[email(), required()]}
            />
            <SelectInput
                label="Статус"
                source="status"
                choices={[
                    { id: 'active', name: 'active' },
                    { id: 'inactive', name: 'inactive' },
                ]}
                validate={required()}
            />
            <BooleanInput
                label="Учет штрафов"
                source="with_fine_fee"
            />
            <TextInput
                label="Контакты"
                source="contact"
                validate={required()}
            />
            <TextInput
                label="Id Telegram-чата"
                source="telegram_chat_id"
            />
            <TextInput
                disabled
                label="Время создания"
                source="created"
            />
            <TextInput
                disabled
                label="Последнее обновление"
                source="updated"
            />
        </SimpleForm>
    </Edit>
);
