import React from 'react';
import {
    Datagrid,
    Filter,
    List,
    ReferenceField,
    required,
    SelectInput,
    TextField,
    TextInput,
} from 'react-admin';
import { BulkDeleteButtonAdmin, CustomPagination } from '../utils';

const PartnersFilter = props => (
    <Filter {...props}>
        <SelectInput
            label="Поле"
            source="field"
            choices={[
                { id: 'id', name: 'Id ' },
                { id: 'partner_service_id', name: 'Id сервисного партнера' },
            ]}
            alwaysOn
        />
        <TextInput
            label="Поиск"
            source="q"
            alwaysOn
        />
    </Filter>
);

export const PartnerServiceContractsList = ({ permissions, ...props }) => (
    <List
        {...props}
        filters={<PartnersFilter />}
        bulkActionButtons={<BulkDeleteButtonAdmin permissions={permissions} />}
        pagination={<CustomPagination />}
    >
        <Datagrid rowClick="edit">
            <TextField
                label="Id "
                source="id"
            />
            <ReferenceField
                label="Сервисный партнер"
                source="partner_service_id"
                reference="partner_services"
                linkType={false}
                validate={required()}
                allowEmpty
            >
                <TextField source="name" />
            </ReferenceField>
            <TextField
                label="Id сервисного партнера"
                source="partner_service_id"
            />
        </Datagrid>
    </List>
);
